import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import MovieCard from "./MovieCard";
import movieList from "./MovieList";
import NoResults from "./NoResults";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import ScrollArrow from "./ScrollArrow";
import EmployeePicks from "./EmployeePicks";

const label = { inputProps: { "aria-label": "Switch demo" } };

function Ratings() {
  let [search, setSearch] = useState(null);
  let [alphabet, setAlphabet] = useState(false);
  let [year, setYear] = useState(false);
  let [original, setOriginal] = useState(false);
  let [value, setValue] = React.useState("Original");

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  let alphabetSelection = () => {
    setAlphabet(
      movieList
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((movie) => {
          return (
            <MovieCard
              key={movie.id}
              name={movie.name}
              poster={movie.poster}
              rating={movie.rating}
              year={movie.year}
              synopsis={movie.synopsis}
              genre={movie.genre}
              subgenre={movie.subgenre}
              runtime={movie.runtime}
              rated={movie.rated}
              picture1={movie.picture1}
              picture2={movie.picture2}
              picture3={movie.picture3}
              tagline={movie.tagline}
              taglineWriter={movie.taglineWriter}
              bulletOne={movie.bulletOne}
              bulletTwo={movie.bulletTwo}
              cast={movie.cast}
              pairs={movie.pairs}
            />
          );
        })
    );
  };

  let yearSelection = () => {
    setYear(
      movieList
        .sort((a, b) => b.year - a.year)
        .map((movie) => {
          return (
            <MovieCard
              key={movie.id}
              name={movie.name}
              poster={movie.poster}
              rating={movie.rating}
              year={movie.year}
              synopsis={movie.synopsis}
              genre={movie.genre}
              subgenre={movie.subgenre}
              runtime={movie.runtime}
              rated={movie.rated}
              picture1={movie.picture1}
              picture2={movie.picture2}
              picture3={movie.picture3}
              tagline={movie.tagline}
              taglineWriter={movie.taglineWriter}
              bulletOne={movie.bulletOne}
              bulletTwo={movie.bulletTwo}
              cast={movie.cast}
              pairs={movie.pairs}
            />
          );
        })
    );
  };

  let originalSelection = () => {
    setOriginal(
      movieList
        .sort((a, b) => a.id - b.id)
        .map((movie) => {
          return (
            <MovieCard
              key={movie.id}
              name={movie.name}
              poster={movie.poster}
              rating={movie.rating}
              year={movie.year}
              synopsis={movie.synopsis}
              genre={movie.genre}
              subgenre={movie.subgenre}
              runtime={movie.runtime}
              rated={movie.rated}
              picture1={movie.picture1}
              picture2={movie.picture2}
              picture3={movie.picture3}
              tagline={movie.tagline}
              taglineWriter={movie.taglineWriter}
              bulletOne={movie.bulletOne}
              bulletTwo={movie.bulletTwo}
              cast={movie.cast}
              pairs={movie.pairs}
            />
          );
        })
    );
  };

  let reviewedMovies = movieList.filter((movie) => movie.synopsis !== "");

  let items = reviewedMovies

    .filter(
      (movie) =>
        search === null ||
        movie.name.toLowerCase().includes(search.toLowerCase())
    )
    .map((movie) => {
      return (
        <MovieCard
          key={movie.id}
          name={movie.name}
          poster={movie.poster}
          rating={movie.rating}
          year={movie.year}
          synopsis={movie.synopsis}
          genre={movie.genre}
          subgenre={movie.subgenre}
          runtime={movie.runtime}
          pairs={movie.pairs}
          noPairs={movie.noPairs}
          drinkRecipe={movie.drinkRecipe}
          rated={movie.rated}
          picture1={movie.picture1}
          picture2={movie.picture2}
          picture3={movie.picture3}
          tagline={movie.tagline}
          taglineWriter={movie.taglineWriter}
          bulletOne={movie.bulletOne}
          bulletTwo={movie.bulletTwo}
          bulletThree={movie.bulletThree}
          cast={movie.cast}
        />
      );
    });

  let noResults =
    items.length === 0 ? (
      <div className="renderContainer">
        <NoResults />
      </div>
    ) : (
      <div className="renderContainer">{items}</div>
    );

  return (
    <div>
      <body className="searchBody">
        <div>
          <div className="searchAndGenre">
            <div className="searchAndFilters">
              <TextField
                id="outlined-basic"
                label="Search"
                variant="outlined"
                className="input"
                placeholder="Type a movie"
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>

            <div className="genreSign search">ALL MOVIES</div>
            <div className="filterContainer">
              <div className="filters">
                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    Filter
                  </FormLabel>

                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={value}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="Original"
                      control={<Radio />}
                      onClick={originalSelection}
                      label="Default"
                    />
                    <FormControlLabel
                      value="Alphabetical"
                      control={<Radio />}
                      onClick={alphabetSelection}
                      label="A-Z"
                    />
                    <FormControlLabel
                      value="Chronological"
                      control={<Radio />}
                      onClick={yearSelection}
                      label="Year &#8645;"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
          </div>

          <ScrollArrow />

          <div>{noResults}</div>
        </div>
      </body>
    </div>
  );
}

export default Ratings;
