import React, { useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import movieList from "./MovieList";
import MovieCard from "./MovieCard";
import Box from "@mui/material/Box";
import ScrollArrow from "./ScrollArrow";

let action = movieList
  .filter(
    (movie) =>
      (movie.genre === "Action" && movie.synopsis !== "") ||
      (movie.subgenre.includes("Action") && movie.synopsis !== "")
  )
  .map((movie) => {
    return (
      <MovieCard
        key={movie.id}
        name={movie.name}
        poster={movie.poster}
        rating={movie.rating}
        year={movie.year}
        synopsis={movie.synopsis}
        genre={movie.genre}
        subgenre={movie.subgenre}
        runtime={movie.runtime}
        pairs={movie.pairs}
        noPairs={movie.noPairs}
        drinkRecipe={movie.drinkRecipe}
        rated={movie.rated}
        picture1={movie.picture1}
        picture2={movie.picture2}
        picture3={movie.picture3}
        tagline={movie.tagline}
        taglineWriter={movie.taglineWriter}
        bulletOne={movie.bulletOne}
        bulletTwo={movie.bulletTwo}
        bulletThree={movie.bulletThree}
        cast={movie.cast}
      />
    );
  });

let adventure = movieList
  .filter(
    (movie) =>
      (movie.genre === "Adventure" && movie.synopsis !== "") ||
      (movie.subgenre.includes("Adventure") && movie.synopsis !== "")
  )
  .map((movie) => {
    return (
      <MovieCard
        key={movie.id}
        name={movie.name}
        poster={movie.poster}
        rating={movie.rating}
        year={movie.year}
        synopsis={movie.synopsis}
        genre={movie.genre}
        subgenre={movie.subgenre}
        runtime={movie.runtime}
        pairs={movie.pairs}
        noPairs={movie.noPairs}
        drinkRecipe={movie.drinkRecipe}
        rated={movie.rated}
        picture1={movie.picture1}
        picture2={movie.picture2}
        picture3={movie.picture3}
        tagline={movie.tagline}
        taglineWriter={movie.taglineWriter}
        bulletOne={movie.bulletOne}
        bulletTwo={movie.bulletTwo}
        bulletThree={movie.bulletThree}
        cast={movie.cast}
      />
    );
  });

let animation = movieList
  .filter(
    (movie) =>
      (movie.genre === "Animation" && movie.synopsis !== "") ||
      (movie.subgenre.includes("Animation") && movie.synopsis !== "")
  )
  .map((movie) => {
    return (
      <MovieCard
        key={movie.id}
        name={movie.name}
        poster={movie.poster}
        rating={movie.rating}
        year={movie.year}
        synopsis={movie.synopsis}
        genre={movie.genre}
        subgenre={movie.subgenre}
        runtime={movie.runtime}
        pairs={movie.pairs}
        noPairs={movie.noPairs}
        drinkRecipe={movie.drinkRecipe}
        rated={movie.rated}
        picture1={movie.picture1}
        picture2={movie.picture2}
        picture3={movie.picture3}
        tagline={movie.tagline}
        taglineWriter={movie.taglineWriter}
        bulletOne={movie.bulletOne}
        bulletTwo={movie.bulletTwo}
        bulletThree={movie.bulletThree}
        cast={movie.cast}
      />
    );
  });

let comedies = movieList
  .filter(
    (movie) =>
      (movie.genre === "Comedy" && movie.synopsis !== "") ||
      (movie.subgenre.includes("Comedy") && movie.synopsis !== "")
  )
  .map((movie) => {
    return (
      <MovieCard
        key={movie.id}
        name={movie.name}
        poster={movie.poster}
        rating={movie.rating}
        year={movie.year}
        synopsis={movie.synopsis}
        genre={movie.genre}
        subgenre={movie.subgenre}
        runtime={movie.runtime}
        pairs={movie.pairs}
        noPairs={movie.noPairs}
        drinkRecipe={movie.drinkRecipe}
        rated={movie.rated}
        picture1={movie.picture1}
        picture2={movie.picture2}
        picture3={movie.picture3}
        tagline={movie.tagline}
        taglineWriter={movie.taglineWriter}
        bulletOne={movie.bulletOne}
        bulletTwo={movie.bulletTwo}
        bulletThree={movie.bulletThree}
        cast={movie.cast}
      />
    );
  });

let crime = movieList
  .filter(
    (movie) =>
      (movie.genre === "Crime" && movie.synopsis !== "") ||
      (movie.subgenre.includes("Crime") && movie.synopsis !== "")
  )
  .map((movie) => {
    return (
      <MovieCard
        key={movie.id}
        name={movie.name}
        poster={movie.poster}
        rating={movie.rating}
        year={movie.year}
        synopsis={movie.synopsis}
        genre={movie.genre}
        subgenre={movie.subgenre}
        runtime={movie.runtime}
        pairs={movie.pairs}
        noPairs={movie.noPairs}
        drinkRecipe={movie.drinkRecipe}
        rated={movie.rated}
        picture1={movie.picture1}
        picture2={movie.picture2}
        picture3={movie.picture3}
        tagline={movie.tagline}
        taglineWriter={movie.taglineWriter}
        bulletOne={movie.bulletOne}
        bulletTwo={movie.bulletTwo}
        bulletThree={movie.bulletThree}
        cast={movie.cast}
      />
    );
  });

let cultClassic = movieList
  .filter(
    (movie) =>
      (movie.genre === "Cult Classic" && movie.synopsis !== "") ||
      (movie.subgenre.includes("Cult Classic") && movie.synopsis !== "")
  )

  .map((movie) => {
    return (
      <MovieCard
        key={movie.id}
        name={movie.name}
        poster={movie.poster}
        rating={movie.rating}
        year={movie.year}
        synopsis={movie.synopsis}
        genre={movie.genre}
        subgenre={movie.subgenre}
        runtime={movie.runtime}
        pairs={movie.pairs}
        noPairs={movie.noPairs}
        drinkRecipe={movie.drinkRecipe}
        rated={movie.rated}
        picture1={movie.picture1}
        picture2={movie.picture2}
        picture3={movie.picture3}
        tagline={movie.tagline}
        taglineWriter={movie.taglineWriter}
        bulletOne={movie.bulletOne}
        bulletTwo={movie.bulletTwo}
        bulletThree={movie.bulletThree}
        cast={movie.cast}
      />
    );
  });

let horror = movieList
  .filter(
    (movie) =>
      (movie.genre === "Horror" && movie.synopsis !== "") ||
      (movie.subgenre.includes("Horror") && movie.synopsis !== "")
  )
  .map((movie) => {
    return (
      <MovieCard
        key={movie.id}
        name={movie.name}
        poster={movie.poster}
        rating={movie.rating}
        year={movie.year}
        synopsis={movie.synopsis}
        genre={movie.genre}
        subgenre={movie.subgenre}
        runtime={movie.runtime}
        pairs={movie.pairs}
        noPairs={movie.noPairs}
        drinkRecipe={movie.drinkRecipe}
        rated={movie.rated}
        picture1={movie.picture1}
        picture2={movie.picture2}
        picture3={movie.picture3}
        tagline={movie.tagline}
        taglineWriter={movie.taglineWriter}
        bulletOne={movie.bulletOne}
        bulletTwo={movie.bulletTwo}
        bulletThree={movie.bulletThree}
        cast={movie.cast}
      />
    );
  });

let mystery = movieList
  .filter(
    (movie) =>
      (movie.genre === "Mystery" && movie.synopsis !== "") ||
      (movie.subgenre.includes("Mystery") && movie.synopsis !== "")
  )
  .map((movie) => {
    return (
      <MovieCard
        key={movie.id}
        name={movie.name}
        poster={movie.poster}
        rating={movie.rating}
        year={movie.year}
        synopsis={movie.synopsis}
        genre={movie.genre}
        subgenre={movie.subgenre}
        runtime={movie.runtime}
        pairs={movie.pairs}
        noPairs={movie.noPairs}
        drinkRecipe={movie.drinkRecipe}
        rated={movie.rated}
        picture1={movie.picture1}
        picture2={movie.picture2}
        picture3={movie.picture3}
        tagline={movie.tagline}
        taglineWriter={movie.taglineWriter}
        bulletOne={movie.bulletOne}
        bulletTwo={movie.bulletTwo}
        bulletThree={movie.bulletThree}
        cast={movie.cast}
      />
    );
  });

let romcom = movieList
  .filter(
    (movie) =>
      (movie.genre === "Romcom" && movie.synopsis !== "") ||
      (movie.subgenre.includes("Romcom") && movie.synopsis !== "")
  )
  .map((movie) => {
    return (
      <MovieCard
        key={movie.id}
        name={movie.name}
        poster={movie.poster}
        rating={movie.rating}
        year={movie.year}
        synopsis={movie.synopsis}
        genre={movie.genre}
        subgenre={movie.subgenre}
        runtime={movie.runtime}
        pairs={movie.pairs}
        noPairs={movie.noPairs}
        drinkRecipe={movie.drinkRecipe}
        rated={movie.rated}
        picture1={movie.picture1}
        picture2={movie.picture2}
        picture3={movie.picture3}
        tagline={movie.tagline}
        taglineWriter={movie.taglineWriter}
        bulletOne={movie.bulletOne}
        bulletTwo={movie.bulletTwo}
        bulletThree={movie.bulletThree}
        cast={movie.cast}
      />
    );
  });

let scifi = movieList
  .filter(
    (movie) =>
      (movie.genre === "Scifi" && movie.synopsis !== "") ||
      (movie.subgenre.includes("Scifi") && movie.synopsis !== "")
  )
  .map((movie) => {
    return (
      <MovieCard
        key={movie.id}
        name={movie.name}
        poster={movie.poster}
        rating={movie.rating}
        year={movie.year}
        synopsis={movie.synopsis}
        genre={movie.genre}
        subgenre={movie.subgenre}
        runtime={movie.runtime}
        pairs={movie.pairs}
        noPairs={movie.noPairs}
        drinkRecipe={movie.drinkRecipe}
        rated={movie.rated}
        picture1={movie.picture1}
        picture2={movie.picture2}
        picture3={movie.picture3}
        tagline={movie.tagline}
        taglineWriter={movie.taglineWriter}
        bulletOne={movie.bulletOne}
        bulletTwo={movie.bulletTwo}
        bulletThree={movie.bulletThree}
        cast={movie.cast}
      />
    );
  });
let sports = movieList
  .filter(
    (movie) =>
      (movie.genre === "Sports" && movie.synopsis !== "") ||
      (movie.subgenre.includes("Sports") && movie.synopsis !== "")
  )
  .map((movie) => {
    return (
      <MovieCard
        key={movie.id}
        name={movie.name}
        poster={movie.poster}
        rating={movie.rating}
        year={movie.year}
        synopsis={movie.synopsis}
        genre={movie.genre}
        subgenre={movie.subgenre}
        runtime={movie.runtime}
        pairs={movie.pairs}
        noPairs={movie.noPairs}
        drinkRecipe={movie.drinkRecipe}
        rated={movie.rated}
        picture1={movie.picture1}
        picture2={movie.picture2}
        picture3={movie.picture3}
        tagline={movie.tagline}
        taglineWriter={movie.taglineWriter}
        bulletOne={movie.bulletOne}
        bulletTwo={movie.bulletTwo}
        bulletThree={movie.bulletThree}
        cast={movie.cast}
      />
    );
  });
let superhero = movieList
  .filter(
    (movie) =>
      (movie.genre === "Superhero" && movie.synopsis !== "") ||
      (movie.subgenre.includes("Superhero") && movie.synopsis !== "")
  )
  .map((movie) => {
    return (
      <MovieCard
        key={movie.id}
        name={movie.name}
        poster={movie.poster}
        rating={movie.rating}
        year={movie.year}
        synopsis={movie.synopsis}
        genre={movie.genre}
        subgenre={movie.subgenre}
        runtime={movie.runtime}
        pairs={movie.pairs}
        noPairs={movie.noPairs}
        drinkRecipe={movie.drinkRecipe}
        rated={movie.rated}
        picture1={movie.picture1}
        picture2={movie.picture2}
        picture3={movie.picture3}
        tagline={movie.tagline}
        taglineWriter={movie.taglineWriter}
        bulletOne={movie.bulletOne}
        bulletTwo={movie.bulletTwo}
        bulletThree={movie.bulletThree}
        cast={movie.cast}
      />
    );
  });

let thriller = movieList
  .filter(
    (movie) =>
      (movie.genre === "Thriller" && movie.synopsis !== "") ||
      (movie.subgenre.includes("Thriller") && movie.synopsis !== "")
  )
  .map((movie) => {
    return (
      <MovieCard
        key={movie.id}
        name={movie.name}
        poster={movie.poster}
        rating={movie.rating}
        year={movie.year}
        synopsis={movie.synopsis}
        genre={movie.genre}
        subgenre={movie.subgenre}
        runtime={movie.runtime}
        pairs={movie.pairs}
        noPairs={movie.noPairs}
        drinkRecipe={movie.drinkRecipe}
        rated={movie.rated}
        picture1={movie.picture1}
        picture2={movie.picture2}
        picture3={movie.picture3}
        tagline={movie.tagline}
        taglineWriter={movie.taglineWriter}
        bulletOne={movie.bulletOne}
        bulletTwo={movie.bulletTwo}
        bulletThree={movie.bulletThree}
        cast={movie.cast}
      />
    );
  });

let war = movieList
  .filter(
    (movie) =>
      (movie.genre === "War" && movie.synopsis !== "") ||
      (movie.subgenre.includes("War") && movie.synopsis !== "")
  )
  .map((movie) => {
    return (
      <MovieCard
        key={movie.id}
        name={movie.name}
        poster={movie.poster}
        rating={movie.rating}
        year={movie.year}
        synopsis={movie.synopsis}
        genre={movie.genre}
        subgenre={movie.subgenre}
        runtime={movie.runtime}
        pairs={movie.pairs}
        noPairs={movie.noPairs}
        drinkRecipe={movie.drinkRecipe}
        rated={movie.rated}
        picture1={movie.picture1}
        picture2={movie.picture2}
        picture3={movie.picture3}
        tagline={movie.tagline}
        taglineWriter={movie.taglineWriter}
        bulletOne={movie.bulletOne}
        bulletTwo={movie.bulletTwo}
        bulletThree={movie.bulletThree}
        cast={movie.cast}
      />
    );
  });

let western = movieList
  .filter(
    (movie) =>
      (movie.genre === "Western" && movie.synopsis !== "") ||
      (movie.subgenre.includes("Western") && movie.synopsis !== "")
  )
  .map((movie) => {
    return (
      <MovieCard
        key={movie.id}
        name={movie.name}
        poster={movie.poster}
        rating={movie.rating}
        year={movie.year}
        synopsis={movie.synopsis}
        genre={movie.genre}
        subgenre={movie.subgenre}
        runtime={movie.runtime}
        pairs={movie.pairs}
        noPairs={movie.noPairs}
        drinkRecipe={movie.drinkRecipe}
        rated={movie.rated}
        picture1={movie.picture1}
        picture2={movie.picture2}
        picture3={movie.picture3}
        tagline={movie.tagline}
        taglineWriter={movie.taglineWriter}
        bulletOne={movie.bulletOne}
        bulletTwo={movie.bulletTwo}
        bulletThree={movie.bulletThree}
        cast={movie.cast}
      />
    );
  });

let one = movieList
  .filter((movie) => movie.rating === 1 && movie.synopsis !== "")
  .map((movie) => {
    return (
      <MovieCard
        key={movie.id}
        name={movie.name}
        poster={movie.poster}
        rating={movie.rating}
        year={movie.year}
        synopsis={movie.synopsis}
        genre={movie.genre}
        subgenre={movie.subgenre}
        runtime={movie.runtime}
        pairs={movie.pairs}
        noPairs={movie.noPairs}
        drinkRecipe={movie.drinkRecipe}
        rated={movie.rated}
        picture1={movie.picture1}
        picture2={movie.picture2}
        picture3={movie.picture3}
        tagline={movie.tagline}
        taglineWriter={movie.taglineWriter}
        bulletOne={movie.bulletOne}
        bulletTwo={movie.bulletTwo}
        bulletThree={movie.bulletThree}
        cast={movie.cast}
      />
    );
  });

let two = movieList
  .filter((movie) => movie.rating === 2 && movie.synopsis !== "")
  .map((movie) => {
    return (
      <MovieCard
        key={movie.id}
        name={movie.name}
        poster={movie.poster}
        rating={movie.rating}
        year={movie.year}
        synopsis={movie.synopsis}
        genre={movie.genre}
        subgenre={movie.subgenre}
        runtime={movie.runtime}
        pairs={movie.pairs}
        noPairs={movie.noPairs}
        drinkRecipe={movie.drinkRecipe}
        rated={movie.rated}
        picture1={movie.picture1}
        picture2={movie.picture2}
        picture3={movie.picture3}
        tagline={movie.tagline}
        taglineWriter={movie.taglineWriter}
        bulletOne={movie.bulletOne}
        bulletTwo={movie.bulletTwo}
        bulletThree={movie.bulletThree}
        cast={movie.cast}
      />
    );
  });

let three = movieList
  .filter((movie) => movie.rating === 3 && movie.synopsis !== "")
  .map((movie) => {
    return (
      <MovieCard
        key={movie.id}
        name={movie.name}
        poster={movie.poster}
        rating={movie.rating}
        year={movie.year}
        synopsis={movie.synopsis}
        genre={movie.genre}
        subgenre={movie.subgenre}
        runtime={movie.runtime}
        pairs={movie.pairs}
        noPairs={movie.noPairs}
        drinkRecipe={movie.drinkRecipe}
        rated={movie.rated}
        picture1={movie.picture1}
        picture2={movie.picture2}
        picture3={movie.picture3}
        tagline={movie.tagline}
        taglineWriter={movie.taglineWriter}
        bulletOne={movie.bulletOne}
        bulletTwo={movie.bulletTwo}
        bulletThree={movie.bulletThree}
        cast={movie.cast}
      />
    );
  });

let four = movieList
  .filter((movie) => movie.rating === 4 && movie.synopsis !== "")
  .map((movie) => {
    return (
      <MovieCard
        key={movie.id}
        name={movie.name}
        poster={movie.poster}
        rating={movie.rating}
        year={movie.year}
        synopsis={movie.synopsis}
        genre={movie.genre}
        subgenre={movie.subgenre}
        runtime={movie.runtime}
        pairs={movie.pairs}
        noPairs={movie.noPairs}
        drinkRecipe={movie.drinkRecipe}
        rated={movie.rated}
        picture1={movie.picture1}
        picture2={movie.picture2}
        picture3={movie.picture3}
        tagline={movie.tagline}
        taglineWriter={movie.taglineWriter}
        bulletOne={movie.bulletOne}
        bulletTwo={movie.bulletTwo}
        bulletThree={movie.bulletThree}
        cast={movie.cast}
      />
    );
  });

let five = movieList
  .filter((movie) => movie.rating === 5 && movie.synopsis !== "")
  .map((movie) => {
    return (
      <MovieCard
        key={movie.id}
        name={movie.name}
        poster={movie.poster}
        rating={movie.rating}
        year={movie.year}
        synopsis={movie.synopsis}
        genre={movie.genre}
        subgenre={movie.subgenre}
        runtime={movie.runtime}
        pairs={movie.pairs}
        noPairs={movie.noPairs}
        drinkRecipe={movie.drinkRecipe}
        rated={movie.rated}
        picture1={movie.picture1}
        picture2={movie.picture2}
        picture3={movie.picture3}
        tagline={movie.tagline}
        taglineWriter={movie.taglineWriter}
        bulletOne={movie.bulletOne}
        bulletTwo={movie.bulletTwo}
        bulletThree={movie.bulletThree}
        cast={movie.cast}
      />
    );
  });

let six = movieList
  .filter((movie) => movie.rating === 6 && movie.synopsis !== "")
  .map((movie) => {
    return (
      <MovieCard
        key={movie.id}
        name={movie.name}
        poster={movie.poster}
        rating={movie.rating}
        year={movie.year}
        synopsis={movie.synopsis}
        genre={movie.genre}
        subgenre={movie.subgenre}
        runtime={movie.runtime}
        pairs={movie.pairs}
        noPairs={movie.noPairs}
        drinkRecipe={movie.drinkRecipe}
        rated={movie.rated}
        picture1={movie.picture1}
        picture2={movie.picture2}
        picture3={movie.picture3}
        tagline={movie.tagline}
        taglineWriter={movie.taglineWriter}
        bulletOne={movie.bulletOne}
        bulletTwo={movie.bulletTwo}
        bulletThree={movie.bulletThree}
        cast={movie.cast}
      />
    );
  });

function SimpleSelect() {
  const [age, setAge] = React.useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
    setGenre(event.target.value);
    setRating(event.target.value);
    setClick(true);
  };
  const [genre, setGenre] = useState("");
  const [rating, setRating] = useState("");
  const [click, setClick] = useState(false);

  const handleClickChange = (event) => {};

  let clickedOrNot = !click ? (
    <div>
      <div className="genreSignContainer">
        <div
          className="genreSearchSign"
          style={{
            marginLeft: "35px",
            backgroundColor: "white",
            height: "60px",
          }}
        >
          <Box sx={{ minWidth: 120 }}>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="demo-simple-select-standard-label">
                Select Genre
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={age}
                onChange={handleChange}
                label="Select"
              >
                <MenuItem value={action}>Action</MenuItem>
                <MenuItem value={adventure}>Adventure</MenuItem>
                <MenuItem value={animation}>Animation</MenuItem>
                <MenuItem value={comedies}>Comedy</MenuItem>
                <MenuItem value={crime}>Crime</MenuItem>
                <MenuItem value={cultClassic}>Cult Classics</MenuItem>
                <MenuItem value={horror}>Horror</MenuItem>
                <MenuItem value={mystery}>Mystery</MenuItem>
                <MenuItem value={romcom}>Romcom</MenuItem>
                <MenuItem value={scifi}>Sci-fi</MenuItem>
                <MenuItem value={sports}>Sports</MenuItem>
                <MenuItem value={superhero}>Superhero</MenuItem>
                <MenuItem value={thriller}>Thriller</MenuItem>
                <MenuItem value={war}>War</MenuItem>
                <MenuItem value={western}>Western</MenuItem>
                <MenuItem value={one}>
                  1 Beer{" "}
                  <span role="img" aria-label="beer">
                    🍺
                  </span>
                </MenuItem>
                <MenuItem value={two}>
                  2 Beers{" "}
                  <span role="img" aria-label="beer">
                    🍺
                  </span>
                </MenuItem>
                <MenuItem value={three}>
                  3 Beers{" "}
                  <span role="img" aria-label="beer">
                    🍺
                  </span>
                </MenuItem>
                <MenuItem value={four}>
                  4 Beers{" "}
                  <span role="img" aria-label="beer">
                    🍺
                  </span>
                </MenuItem>
                <MenuItem value={five}>
                  5 Beers{" "}
                  <span role="img" aria-label="beer">
                    🍺
                  </span>
                </MenuItem>
                <MenuItem value={six}>
                  6 Beers{" "}
                  <span role="img" aria-label="beer">
                    🍺
                  </span>
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
        </div>
        <div className="genreSign">COMEDY</div>
        <div className="genreSelectDropdown"></div>
      </div>

      <div className="renderContainer"> {comedies}</div>
      <div> {genre}</div>
    </div>
  ) : (
    <div>
      <div className="genreSignContainer">
        <div
          className="genreSearchSign"
          style={{
            marginLeft: "35px",
            backgroundColor: "white",
            height: "60px",
          }}
        >
          <Box sx={{ minWidth: 120 }}>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="demo-simple-select-standard-label">
                Select Genre
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={age}
                onChange={handleChange}
                label="Select"
              >
                <MenuItem value={action}>Action</MenuItem>
                <MenuItem value={adventure}>Adventure</MenuItem>
                <MenuItem value={animation}>Animation</MenuItem>
                <MenuItem value={comedies}>Comedy</MenuItem>
                <MenuItem value={crime}>Crime</MenuItem>
                <MenuItem value={cultClassic}>Cult Classics</MenuItem>
                <MenuItem value={horror}>Horror</MenuItem>
                <MenuItem value={mystery}>Mystery</MenuItem>
                <MenuItem value={romcom}>Romcom</MenuItem>
                <MenuItem value={scifi}>Sci-fi</MenuItem>
                <MenuItem value={sports}>Sports</MenuItem>
                <MenuItem value={superhero}>Superhero</MenuItem>
                <MenuItem value={thriller}>Thriller</MenuItem>
                <MenuItem value={war}>War</MenuItem>
                <MenuItem value={western}>Western</MenuItem>
                <MenuItem value={one}>
                  1 Beer{" "}
                  <span role="img" aria-label="beer">
                    🍺
                  </span>
                </MenuItem>
                <MenuItem value={two}>
                  2 Beers{" "}
                  <span role="img" aria-label="beer">
                    🍺
                  </span>
                </MenuItem>
                <MenuItem value={three}>
                  3 Beers{" "}
                  <span role="img" aria-label="beer">
                    🍺
                  </span>
                </MenuItem>
                <MenuItem value={four}>
                  4 Beers{" "}
                  <span role="img" aria-label="beer">
                    🍺
                  </span>
                </MenuItem>
                <MenuItem value={five}>
                  5 Beers{" "}
                  <span role="img" aria-label="beer">
                    🍺
                  </span>
                </MenuItem>
                <MenuItem value={six}>
                  6 Beers{" "}
                  <span role="img" aria-label="beer">
                    🍺
                  </span>
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
        </div>

        <div className="genreSign genre">
          {genre === one
            ? rating[0].props.rating + " BEER"
            : genre === two ||
              genre === three ||
              genre === four ||
              genre === five ||
              genre === six
            ? rating[0].props.rating + " BEERS"
            : genre[0].props.genre.toUpperCase()}{" "}
        </div>

        <div className="genreSelectDropdown"></div>
      </div>

      <div className="renderContainer"> {genre}</div>
    </div>
  );

  return (
    <div>
      <ScrollArrow />
      {clickedOrNot}
    </div>
  );
}

export default SimpleSelect;
