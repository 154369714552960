import React from "react";
import GambolMain from "../topfive-pictures/gambol-main.jpeg";
import GambolKnife from "../topfive-pictures/gambol-knife.jpeg";
import PhasmaMain from "../topfive-pictures/phasma-main.jpeg";
import PhasmaBright from "../topfive-pictures/phasma-bright.jpeg";
import BillyMain from "../topfive-pictures/billy-main.jpeg";
import BillyLaugh from "../topfive-pictures/billy-laugh.jpeg";
import Areoax from "../topfive-pictures/areo-ax.jpeg";
import AreoSmoke from "../topfive-pictures/areo-smoke.jpeg";
import disappointed from "../topfive-pictures/disappointed.jpeg";
import { Link } from "react-router-dom";

function FlexTest() {
  return (
    <div>
      <div className="currentRoute">
        <Link to="/articles">
          <span className="underline">Articles</span>
        </Link>{" "}
        &gt;{" "}
        <Link to="writingtopFive">
          <span className="underline">Top 5 Lists</span>
        </Link>{" "}
        &gt; FlexTest
      </div>
      <div className="articleContentContainer">
        <div class="article-container">
          <div class="article-container-header">
            <p>Most Disappointing Badasses</p>
          </div>
          <div className="published">PUBLISHED Oct 24, 2022</div>
          <div className="published">
            BY <span className="green">AP</span>
          </div>
          <div class="article-container-body">
            <div className="image-and-caption">
              <img
                src={disappointed}
                className="article-image"
                alt={"Player Avatar"}
                style={{ objectFit: "fill" }}
              />
              <p id="image-caption">Gotta love the commitment</p>
            </div>
            <p>
              Oftentimes in film and TV we see stories of unlikely characters
              achieve incredible feats. They become who they were meant to be
              and save the day. We’re told not to judge a book by its cover.
            </p>

            <p>
              Other times we have characters who immediately look and seem
              awesome. They have huge build-ups and great backstories, carry
              cool weapons we can’t wait to see kick ass, and deliver bone
              chilling lines.
            </p>
            <p>
              Then there are those who fart in the wind when it comes to actual
              action; ruining what would have been a memorable character. Some
              of these iconic characters' disappointment really boils down to a
              lackluster demise. Either way, don’t judge a book by its cover.
            </p>
            <p>Here are the Most Disappointing Badasses</p>
            <h2>5.) GAMBOL (The Dark Knight)</h2>
            <div className="image-and-caption">
              <img
                src={GambolMain}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">Here's an image caption</p>
            </div>
            <p>
              Gambol held his own as a mob leader in the Gotham underworld.
              Gaining territory from and then allying with the Chechen gang and
              Italian mafia. Plus his expensive suit couldn’t hide MJW’s
              bodybuilder physique.
            </p>

            <p>
              Ever vigilant with his growing empire, Gambol was the only one who
              saw the Joker for what he was: a clown. His contemporaries
              wouldn’t agree, so fuck ‘em, he took it upon himself to stop the
              lip licking lunatic.
            </p>

            <div className="image-and-caption">
              <img
                src={GambolKnife}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">Here's an image caption</p>
            </div>
            <p>
              All for nothing because he just learns about the history of some
              scar tissue, puts up ZERO fight, and a swift Hefty bag over his
              head ends the scene. Even his henchman fell for that stupid magic
              trick. LAME!
            </p>
            <h2>4.) PHASMA (Star Wars: The Force Awakens)</h2>
            <div className="image-and-caption">
              <img
                src={PhasmaMain}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">Here's an image caption</p>
            </div>
            <p>
              Captain Phasma the only Silver Storm Trooper. She wears a cool red
              and black cape, carries a bitching gunsmoke blaster, and spits ice
              through her intercom. When ordered to destroy a village of
              treasonous peasants she immediately begins the deadly countdown.
            </p>

            <p>
              Clearly Phasma has earned that armor and rank for a reason. Can’t
              they learn from Star Trek and hire some red shirt losers to show
              off the “bad guys” talents a bit? Let’s see her smoke some scum!
            </p>

            <div className="image-and-caption">
              <img
                src={PhasmaBright}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">Here's an image caption</p>
            </div>
            <p>
              Instead she just kind of meanders in the way of the plot for half
              a second or so not giving any thought to being an obstacle for the
              terrorists. Despite her stellar sheen & polish-job the rebels
              remain unblinded and, in a lame call-back joke, this shiny turd
              lands where she belongs.
            </p>
            <h2>2.) BILLY SOLE (Predator)</h2>
            <div className="image-and-caption">
              <img
                src={BillyMain}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">Here's an image caption</p>
            </div>
            <p>
              The expert among experts in tracking, hunting, and killing. Billy
              understood the Predator better than anyone else on a team full of
              CIA spec-ops commandos; each more grizzled and manly than the
              last.
            </p>

            <p>
              After seeing a few of his buddies get mutilated, Billy decides
              that he’s hardcore enough to face the murdering monster alone and
              even does a sweet chest cutting taught. We’re definitely building
              up to a spectacular showdown from this bloodthirsty warrior.
            </p>

            <div className="image-and-caption">
              <img
                src={BillyLaugh}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">Here's an image caption</p>
            </div>
            <p>
              Oh nope, off screen death. And there’s his limp sack of skin. What
              a waste of a cool character.
            </p>
            <h2>1.) AREO HOTAH (Game of Thrones)</h2>
            <div className="image-and-caption">
              <img
                src={Areoax}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">Here's an image caption</p>
            </div>
            <p>
              Captain of the Guards to House Martell. This dude is huge, he
              wields a massive axe-spear hybrid, and doesn’t take shit from
              anyone. He fuckin’ clocked Bronn on his crocodile snout and didn’t
              break a sweat; anyone else’s hand would’ve imploded.
            </p>

            <p>
              The guy tongue lashes Jaime Lannister, who is second in wise-ass
              comebacks only to his brother Tyrion. I can’t wait to see him in
              “a fair fight” with someone who has both hands and knows what
              they’re doing with a blade— WAIT what the fuck? He just gets
              stabbed in the back!
            </p>

            <div className="image-and-caption">
              <img
                src={AreoSmoke}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">Here's an image caption</p>
            </div>
            <p>
              Captain Hotah deserved an epic fight, even if he needed to die to
              progress a storyline which went nowhere. Barristan Selmy died like
              the actual badass he was… Why couldn’t Areo have gone out swinging
              his ax? He talked a big talk and we never got to see him (or his
              Prince) walk the walk.
            </p>
            <p>
              <strong>Honorable mentions:</strong> Karl Honig (Johnny Mnemonic),
              Black Noir ([Death Scene] The Boys)
            </p>
          </div>
          <div className="readMoreContainer">
            <p className="readMoreHeader">Read next:</p>
            <p className="readMoreContent">Here is an article title</p>
            <p className="underscore"></p>
          </div>
        </div>
        <div className="relatedSidebar">
          <p className="seeAlso">You may also like ...</p>

          <div className="image-and-caption">
            <img
              src={BillyMain}
              className="article-image"
              alt={"Player Avatar"}
            />
            <p id="sidebar-image-caption">Here's an image caption</p>
          </div>
          <div className="image-and-caption">
            <img
              src={PhasmaMain}
              className="article-image"
              alt={"Player Avatar"}
            />
            <p id="sidebar-image-caption">Here's an image caption</p>
          </div>
          <div className="image-and-caption">
            <img
              src={GambolMain}
              className="article-image"
              alt={"Player Avatar"}
            />
            <p id="sidebar-image-caption">Here's an image caption</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FlexTest;
