import React from "react";
import { Link } from "react-router-dom";
import ShareCircle from "../ShareCircle.js";
import ShareSquare from "../ShareSquare";
import odevideo from "../grabbag-pictures/odethumbnail-black.jpeg";
import Morpheus2 from "../coldtake-pictures/Morpheus_Scumbag.jpg";
import MorpheusSuit from "../coldtake-pictures/pimp-suit.jpeg";
import DumpBuilding from "../coldtake-pictures/dump-building.png";
import Creephands from "../coldtake-pictures/creephands.png";
import Morphekiss from "../coldtake-pictures/morphekiss.png";
import Busted from "../coldtake-pictures/busted.png";
import Cypher from "../coldtake-pictures/cypher.png";
import Oracle2 from "../coldtake-pictures/oracle2.jpeg";
import Gross from "../coldtake-pictures/gross.png";
import Sheo from "../coldtake-pictures/Sheo.png";
import Ghislaine from "../coldtake-pictures/ghislaine.png";
import Nochair from "../coldtake-pictures/nochairs.png";
import Pills from "../coldtake-pictures/matrixpills.jpeg";
import biggestguns from "../pictures/topfive/taglines.jpeg";
import RareSequel from "../pictures/topfive/sequelwins.jpeg";
import NotificationBell from "../NotificationBell";

function Morpheus() {
  return (
    <div>
      <div className="currentRoute">
        <Link to="/articles">
          <span className="underline">Articles</span>
        </Link>{" "}
        &gt;{" "}
        <Link to="writingGrabbag">
          <span className="underline">Grab Bag</span>
        </Link>{" "}
        &gt; Morpheus, the Pimp
      </div>
      <div className="articleContentContainer">
        <div class="article-container">
          <div class="article-container-header">
            <p>Morpheus, the Pimp </p>
          </div>
          <div className="published">PUBLISHED Sep 25, 2023</div>
          <div className="authorName">
            BY{" "}
            <p>
              <Link to="/dean">
                <span className="green" id="author-name">
                  Dean
                </span>
              </Link>
            </p>
            <ShareCircle
              description={
                "Check out this article from FilmSlobs! Morpheus, the Pimp "
              }
            />
          </div>
          <div class="article-container-body">
            <p>
              <strong>*Warning: article contains spoilers.*</strong>
            </p>
            <p>
              Neo had it all: a job at a top software company, a booming
              mini-disc side hustle, and a ticket to a long, happy life. But
              that all changed when he fell under the spell of a{" "}
              <span className="green">pill-pushing pimp</span> named Morpheus.
            </p>
            <div className="image-and-caption">
              <img
                src={Morpheus2}
                className="article-image"
                alt={"Player Avatar"}
                style={{ objectFit: "fill" }}
              />
              <p id="image-caption">
                See you in Zion! Btw you'll wake up in a gel sac and get choked
                by a robot octopus
              </p>
            </div>
            <p>
              In the murky depths of the underworld, crowning the{" "}
              <span className="green"> biggest piece of shit</span> is a tall
              task. Among the contenders vying for this dubious honor are
              murderers, drug dealers, and a plethora of ne'er-do-wells – but
              near the top of the heap stands pimps, and at the top of the pimps
              stands <span className="green">Morpheus</span>.
            </p>
            <p>
              You might be protesting, 'Morpheus is NOT a pimp!' But if we peel
              back the layers of the matrix for a moment, the evidence becomes
              quite damning. Prepare to swallow the red pill of truth as we
              delve into Morpheus'{" "}
              <span className="green">blatant scumbaggery.</span>
            </p>
            <h2>Swindlers Paradise</h2>

            <p>
              To ensnare and control Neo, Morpheus busts out the Pimp Handbook
              and <span className="green">pulls every trick.</span> Whether it's
              grooming and manipulation or false promises and exploitation, he
              doesn't miss an opportunity to prey upon Neo's vulnerabilities.
              We'll present eight (8) individual pieces of evidence to support
              our 'Morpheus is a pimp' claim, but let's just start at the
              surface for good measure.
            </p>
            <p>
              <span className="green">1.) HE LOOKS LIKE A PIMP</span>
            </p>
            <p>
              From the alligator coat to the shiny purple three-piece, he{" "}
              <em>could not</em> be more flashy. Fuckin' guy looks like a pickup
              artist in a zoot-suit crashing a Halloween party. The only thing
              missing from his getup is a fedora with a peacock feather.
            </p>
            <div className="image-and-caption">
              <img
                src={MorpheusSuit}
                className="article-image"
                alt={"Player Avatar"}
                style={{ objectFit: "fill" }}
              />
              <p id="image-caption">
                Oh I <em>know</em> you aint talkin' bout me, ho
              </p>
            </div>
            <p>
              <span className="green">2.) He operates in a slum</span>
            </p>

            <p>
              Could he have picked a shadier part of town to meet in? The
              building is an absolute dump, and the fleapit interior makes the
              Trainspotting crack house look like Buckingham Palace.
            </p>
            <div className="image-and-caption">
              <img
                src={DumpBuilding}
                className="article-image"
                alt={"Player Avatar"}
                style={{ objectFit: "fill" }}
              />
              <p id="image-caption">Love the wallpaper</p>
            </div>
            <p>
              <span className="green">3.) He's a groomer</span>
            </p>
            <p>
              Like most pimps, Morpheus uses flattery and attention to gain
              trust and emotional dependence. After stalking Neo online, when
              they meet in person he purrs "I'm so honored to meet you. You're
              special - you've always known something was wrong, and your
              stunning intuition brought you to me. I'm the only one that can
              help your sweet ass. We'll be a perfect team, baby".
            </p>

            <p>
              <span className="green">4.) He's a pill-pusher</span>
            </p>
            <p>
              Morpheus doesn't say, "you don't have to take any pills at all if
              you don't want to". He says{" "}
              <em>
                <span className="green">this is your last chance</span>
              </em>
              , as if Neo will die if he refuses to bow to peer pressure.
            </p>
            <div className="image-and-caption">
              <img
                src={Pills}
                className="article-image"
                alt={"Player Avatar"}
                style={{ objectFit: "fill" }}
              />
              <p id="image-caption">Red is RAD! And blue is BOO!</p>
            </div>
            <p>
              <span className="green">
                5.) He uses his incredible powers of seduction for recruiting
              </span>
            </p>
            <p>
              Think about how many innocent victims Morpheus smooth-talked. We
              don't see all 'the ones' who died or killed themselves before Neo,
              but it's clear he charmed Trinity, Cypher, Apoc, Mouse, and Switch
              - who's just a blonde Neo.
            </p>
            <div className="image-and-caption">
              <img
                src={Sheo}
                className="article-image"
                alt={"Player Avatar"}
                style={{ objectFit: "fill" }}
              />
              <p id="image-caption">AKA 'She-o'</p>
            </div>
            <p>
              His alluring and flirtatious habits are undeniable. Consider his
              opening line to Neo - "I don't know if you're ready to see what I
              want to show you". That line exclusively precedes slipping out of
              a silk robe to expose genital piercings. It just feels{" "}
              <span className="green">slimy</span>. And speaking of unwanted
              sliminess, Morpheus is a master of the go-to move for creepy
              bosses: slinking up from behind and caressing shoulders.
            </p>
            <div className="image-and-caption">
              <img
                src={Creephands}
                className="article-image"
                alt={"Player Avatar"}
                style={{ objectFit: "fill" }}
              />
              <p id="image-caption">Whatcha workin' on?</p>
            </div>
            <p>
              He's such a hypnotic, silver-tongued devil that he may have even
              <span className="green"> seduced an agent</span>.
            </p>
            <p>
              {" "}
              While Neo and Trinity storm the compound, Morpheus and Agent smith
              are alone in a room together. We don't know for sure what went on
              in there, but when the other agents burst in, Smith jumps away
              from Morpheus and hastily adjusts his earpiece. It's like watching
              a hooker zip up her blouse during a sting.
            </p>
            <div className="image-and-caption">
              <img
                src={Busted}
                className="article-image"
                alt={"Player Avatar"}
                style={{ objectFit: "fill" }}
              />
              <p id="image-caption"></p>
            </div>
            <div className="image-and-caption">
              <img
                src={Morphekiss}
                className="article-image"
                alt={"Player Avatar"}
                style={{ objectFit: "fill" }}
              />
              <p id="image-caption">Busted. In more ways than one.</p>
            </div>
            <p>
              <span className="green">
                6.) He's a complete liar who offers nothing but false promises{" "}
              </span>
            </p>
            <p>
              The only way he could get Neo (or anyone in his stable) to come to
              the 'real world' is through outright lies and negging. His whole
              pitch is, "Zion is awesome! Don't you want to discover the truth?
              Your life here is fake. You live in a simulation, and simulations
              are gay".
            </p>
            <p>
              He deliberately omits that life in the 'real world' is aboard a
              decrepit ship in the bowels of an annihilated Earth where humans
              subsist on nothing but lumpy, cummy gruel while hiding from
              robotic assassins. And for a guy who hates simulations, Morpheus
              sure is eager to get back into the matrix at any and all
              opportunities. He <span className="green">CAN'T WAIT</span> to
              leave the 'real world'. He's like, "load up the martial arts
              simulation, or the woman in red, or just anything goddamnit,{" "}
              <span class="green">get me outta here!</span>".
            </p>
            <p>
              {" "}
              Cypher - <span className="green">
                the true hero of the movie
              </span>{" "}
              - said it best, "He lied to us. He tricked us. If you’d have told
              us the truth, we'd have told you to shove the red pill straight up
              your ass".
            </p>
            <div className="image-and-caption">
              <img
                src={Cypher}
                className="article-image"
                alt={"Player Avatar"}
                style={{ objectFit: "fill" }}
              />
              <p id="image-caption">
                And since you can't move, the red pill may not be the only thing
                going up there ...
              </p>
              <p>
                To which Trinity, like a ho with Stockholm Syndrome, retorts,
                "No! Morpheus set us free! He loves us!". And Cypher, like
                another battered ho, says, "Free?!{" "}
                <span class="green">All I do is what he tells me to</span>".
              </p>
            </div>

            <p>
              <span className="green">
                7.) Once he has you, you can't leave
              </span>
              <p>
                After Neo discovers the nightmarish truth of the 'real world',
                he obviously wants out immediately ... but Morpheus's bitches
                can't just up and go. Neo says, "I can't really go back, can
                I?". Morpheus replies with the{" "}
                <span className="green">dumbest question ever</span>: "No, but
                if you could, would you really want to?".
              </p>
              <div className="image-and-caption">
                <img
                  src={Gross}
                  className="article-image"
                  alt={"Player Avatar"}
                  style={{ objectFit: "fill" }}
                />
                <p id="image-caption">FUCKIN' OF COURSE I WOULD</p>
              </div>

              <p>
                The only excuse he offers for his villainous deceit is the same
                excuse of the Nazi's. "I did what I did because I had to".
              </p>
              <p>Oh, cool. All is forgiven then. Duty is duty.</p>
            </p>
            <p>
              <span className="green">
                8.) He associates with other scumbags
              </span>
            </p>
            <p>
              Tricking adults into ruining their lives is despicable enough, but
              Morpheus's idol - The Oracle - takes evil a step further by
              grooming children to be 'the one'.{" "}
              <span className="green">
                The Oracle is Morpheus's Ghislaine Maxwell,
              </span>{" "}
              except instead of teaching how to jerk goons, she teaches how to
              bend spoons.
            </p>

            <div className="image-and-caption">
              <img
                src={Ghislaine}
                className="article-image"
                alt={"Player Avatar"}
                style={{ objectFit: "fill" }}
              />
              <p id="image-caption">They even have the same barber</p>
            </div>
            <p>
              {" "}
              She doesn't give a fuck about the children, either. When Neo
              breaks the vase, she's like, "Ah I don't give a shit, I'll make
              one of the lousy kids fix it". The 'other potentials' are simply
              disposable, future money-makers. Even the crew of the
              Nebuchadnezzar are completely expendable to her. If she gave a
              single fuck about them, she could've hinted that Cypher may
              <span className="green"> MURDER THEM ALL.</span>
            </p>
            <p>
              But of course she didn't predict that, because in addition to
              being an irredeemable <span className="green">slime bucket</span>,
              The Oracle is also the biggest charlatan in the entire universe.
              Everything she says and does smacks of the cheapest roadside
              parlor trick, like when Neo arrives and her minion smugly
              proclaims, "Hello, Neo.{" "}
              <span className="green">Right on time</span>". Or when he enters
              the kitchen and she says, "I'd ask you to sit down, but you're not
              going to anyway".
            </p>
            <div className="image-and-caption">
              <img
                src={Nochair}
                className="article-image"
                alt={"Player Avatar"}
                style={{ objectFit: "fill" }}
              />
              <p id="image-caption">Yeah ... there's no other chair in here.</p>
            </div>
            <p>
              And back to the vase -- she perched it in an extremely precarious
              corner of a table right in the middle of a doorway. She's just
              setting booby traps so she can say,{" "}
              <span className="green">"SEE! I'm The Oracle"</span>. Or when she
              tells Neo he's not the one, Neo says, "Morpheus almost had me
              convinced", and she sneers, "I know".{" "}
              <span className="green">NO, YOU DIDN'T</span>.
            </p>
            <p>
              It's truly baffling that store brand Miss Cleo manages to maintain
              any credibility at all, considering she has{" "}
              <span className="green">
                never made a correct prediction, ever.
              </span>{" "}
              It's exceptionally telling that after Neo asks "And she's never
              wrong?", Morpheus answers, "Try not to think of it in terms of
              right and wrong". Oh, perfect. That way she's always right no
              matter what. She's probably got a junk drawer overflowing with
              scratch-off lotto tickets that she 'knew were going to lose'.
            </p>
            <p>
              We can't definitively say how many other 'the ones' she was wrong
              about, but Morpheus stressed that she'd been prognosticating for a
              long time, so it's gotta be a ton. The sheer fact that there's a
              room full of potentials should be an{" "}
              <span className="green">enormous red flag</span>. That's like
              saying "I know who's going to win the Superbowl" and then betting
              on every single team at the beginning of the season.
            </p>
            <p></p>
            <div className="image-and-caption">
              <img
                src={Oracle2}
                className="article-image"
                alt={"Player Avatar"}
                style={{ objectFit: "fill" }}
              />
              <p id="image-caption">SEE! I'm The Oracle</p>
            </div>
            <p>
              Let's forget the fact that she was also wrong about Trinity and
              Cypher being 'the one' (so again, credibility should vanish
              immediately), but she was{" "}
              <span className="green"> wrong about Neo, too</span>. She flat-out
              said he was <span className="green">not the one</span>.
            </p>
            <p>
              There's undoubtedly going to be people who say, "No, she was
              right! He became the one after the agents killed him. Therefore,
              <em> technically</em>, that earlier form of Neo wasn't 'the one'".
              Ah, of course. The ol' "try not to think of it in terms of right
              and wrong" <span className="green">cop-out</span>. If you believe
              that, then The Oracle has brainwashed you better than Morpheus,
              The Pimp.
            </p>

            <h2>Conclusion</h2>
            <p>
              In light of the evidence presented and the deliberation of the
              aforementioned eight (8) points, we pronounce a verdict of guilty
              upon Morpheus for his role in the procurement and manipulation of
              vulnerable individuals for illicit purposes akin to that of a
              <span className="green"> low-life pimp. </span>
            </p>
            <p>
              The sentence to be imposed is nothing short of the most severe
              punishment conceivable - life in the 'real world' without
              possibility for simulation.
            </p>
          </div>{" "}
          <Link to="/dramaischeap">
            <div className="readMoreContainer">
              <p className="readMoreHeader">Read next:</p>
              <p className="readMoreContent">Drama is Cheap</p>
              <p className="underscore"></p>
            </div>
          </Link>
          <ShareSquare
            description={
              "Check out this article from FilmSlobs! Morpheus, the Pimp "
            }
          />
          <div>
            <Link to="/email">
              <NotificationBell />
            </Link>
          </div>
        </div>
        <div className="relatedSidebar">
          <p className="seeAlso">You may also like ...</p>
          <Link to="/biggestguns">
            <div className="image-and-caption">
              <img
                src={biggestguns}
                className="article-image-sidebar"
                alt={"Player Avatar"}
              />
              <p id="sidebar-image-caption">Biggest Guns on Movie Posters</p>
            </div>
          </Link>
          <Link to="/odevideostore">
            <div className="image-and-caption">
              <img
                src={odevideo}
                className="article-image-sidebar"
                alt={"Player Avatar"}
              />
              <p id="sidebar-image-caption">Ode to the video store</p>
            </div>
          </Link>
          <Link to="/raresequelwins">
            <div className="image-and-caption">
              <img
                src={RareSequel}
                className="article-image-sidebar"
                alt={"Player Avatar"}
              />
              <p id="sidebar-image-caption">Rare Sequel Wins</p>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Morpheus;
