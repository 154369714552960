import React from "react";
import { Link } from "react-router-dom";

function About() {
  return (
    <div>
      <section>
        <div className="aboutTextContainer">
          <h1 className="weAreTitle">
            We are <span className="green">FilmSlobs</span>
          </h1>
          <h4>Providing the best movie reviews</h4>
          <br></br>{" "}
          <p>
            This site is <span class="green">for</span> movie lovers,{" "}
            <span class="green">by</span> movie lovers, and our mission is
            simple -- to provide <span class="green">fair</span> ratings and
            reviews from a non-snobbish perspective.
          </p>
          <br></br>
          <p>
            {" "}
            99% of reviews come from critics or 'industry experts' whose sole
            objective is to criticize.{" "}
            <span class="green"> It's time for a change.</span>
          </p>
          <br></br>
          <p>
            Snobs have called us philistines with puerile reviews, but we don't
            even know what 'philistine' or 'puerile' mean, so suck on THAT,
            nerds!
          </p>
          <br></br>
          <p>
            If you have any questions, check out our{" "}
            <Link to="faq" className="faqLink">
              FAQ page.
            </Link>{" "}
            If you want to be awesome, consider{" "}
            <a
              href="https://www.buymeacoffee.com/filmslobs"
              target="_blank"
              className="faqLink"
              rel="noopener noreferrer"
            >
              buying us a beer.
            </a>
          </p>
        </div>
      </section>
      <br></br>
      <br></br> <br></br> <br></br> <br></br> <br></br> <br></br>
    </div>
  );
}

export default About;
