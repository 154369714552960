import React from "react"

function RecipeCard(props) {
  // let body = (
  //   <div className="modal">
  //     <div className="modal-content">
  //       <h2 className="tagline">"{props.tagline}"</h2>
  //       <span className="tagline-writer">- {props.taglineWriter}</span>

  //       <div className="topPicsContainer">
  //         <img src={props.picture1} alt="movie poster" className="picture1" />
  //         <img src={props.picture2} alt="movie poster" className="picture2" />
  //       </div>
  //       <p className="modal-synopsis">{props.synopsis}</p>
  //       <div className="bonusFeatures">
  //         <div className="pictureThreeContainer">
  //           <img
  //             src={props.picture3}
  //             alt="movie poster "
  //             className="picture3"
  //           />
  //         </div>
  //         <div className="letsGo">
  //           <div className="bonusFeatureBulletContainer">SPECIAL FEATURES</div>
  //           <div className="bonusFeatureBullets">
  //             <ul>
  //               <li>{props.bulletOne}</li>
  //               <li className="bottomBullet">{props.bulletTwo}</li>
  //             </ul>
  //           </div>
  //         </div>
  //       </div>
  //       <div className="cast">{props.cast}</div>
  //       <div className="bottomContainer">
  //         <div className="iconOuterContainer">
  //           <div className="iconContainer">
  //             <i
  //               className="fas fa-cocktail fa-2x"
  //               style={{ color: "#c5c1c1" }}></i>
  //           </div>
  //         </div>
  //         <div className="pairsWith">
  //           <div className="pairsWithTextDiv">
  //             This movie pairs well with {props.pairs}
  //           </div>
  //         </div>
  //       </div>

  //       <div className="modal-filmInfo">
  //         <div className="modal-mpaa-rating">{props.rated}</div>
  //         <div className="modal-runtime">
  //           <i className="far fa-clock"></i>
  //           {props.runtime}
  //         </div>
  //         <div className="modal-year">{props.year}</div>
  //         <div className="modal-rating">
  //           <span role="img" aria-label="beer">
  //             🍺
  //           </span>
  //           {props.rating}
  //           <span className="outOf">/6</span>
  //           <span style={{ display: "none" }}>
  //             {props.genre}
  //             {props.subgenre}
  //           </span>
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // )

  return (
    <div className="recipeContainer">
      <div className="recipeIngredients">Ingredients: {props.drinkRecipe}</div>
      <div className="relatedFlex"></div>
    </div>
  )
}

export default RecipeCard
