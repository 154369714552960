import React from "react";
import PhasmaMain from "../topfive-pictures/phasma-main.jpeg";
import { Link } from "react-router-dom";
import ShareCircle from "../ShareCircle.js";
import ShareSquare from "../ShareSquare";
import UtahGirl from "../coldtake-pictures/utahgirl.jpeg";
import HappyCommunity from "../coldtake-pictures/utahbodhi.jpeg";
import Classroom from "../coldtake-pictures/mad-utah.jpg";
import Funeral from "../coldtake-pictures/utahboard.jpeg";
import Dumb from "../coldtake-pictures/utahdumb.jpeg";
import Hacking from "../grabbag-pictures/hacking.png";
import arabia from "../../src/coldtake-pictures/arabia.jpeg";
import NotificationBell from "../NotificationBell";

function Johnny() {
  return (
    <div>
      <div className="currentRoute">
        <Link to="/articles">
          <span className="underline">Articles</span>
        </Link>{" "}
        &gt;{" "}
        <Link to="writingColdTakes">
          <span className="underline">Cold Takes</span>
        </Link>{" "}
        &gt; Johnny Utah is a Piece of Shit
      </div>
      <div className="articleContentContainer">
        <div class="article-container">
          <div class="article-container-header">
            <p>Johnny Utah is a Piece of Shit</p>
          </div>
          <div className="published">PUBLISHED Jul 21, 2023</div>
          <div className="authorName">
            BY{" "}
            <p>
              <Link to="/Ashton">
                <span className="green" id="author-name">
                  Ashton
                </span>
              </Link>
            </p>
            <ShareCircle
              description={
                "Check out this article from FilmSlobs! Johnny Utah is a Piece of Shit."
              }
            />
          </div>
          <div class="article-container-body">
            <p>
              In case you need a heads up... Spoilers for 𝘗𝘰𝘪𝘯𝘵 𝘉𝘳𝘦𝘢𝘬 below...
            </p>
            <div className="image-and-caption">
              <img src={Dumb} className="article-image" alt={"Player Avatar"} />
              <p id="image-caption">"Whoa... That title (wave) is harsh."</p>
            </div>
            <p>
              𝘗𝘰𝘪𝘯𝘵 𝘉𝘳𝘦𝘢𝘬 is a great movie and after more than 30 years since
              hitting theaters the action sequences, jokes, and girls
              (technically, Babes) hold up.
            </p>

            <p>
              Keanu and Swayze are great together, plus Busey delivers another
              stellar psychotic perfomance. The movie definitely doesn't lose
              any points in casting. We're all so used to seeing Reeves as the
              tough guy with a heart of gold. You know he'll do what's right,
              not always what's easy. However, that's definitely not the case
              here because Johnny Utah is a total piece of shit.
            </p>
            <div className="image-and-caption">
              <img
                src={UtahGirl}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">
                "Yeah cool, whatever, my dog died today too."
              </p>
            </div>
            <p>He is a liar.</p>
            <p>
              Johnny barely hesitates before completely lying about his dead
              parents to manipulate Tyler, who in a rare vulnerable moment tells
              him about the death of her parents. He couldn't even come up with
              a good lie, he just snakes her backstory. Of course he gets away
              with his conscience feeling fine because this is all for the
              investigation (which he doesn't really care about anyway, but I'll
              get to that). Utah also lies about his Law School football
              scholarship. There's no such thing.
            </p>
            <div className="image-and-caption">
              <img
                src={Classroom}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">"YOU GOTTA BE FUCKING KIDDING ME!"</p>
            </div>
            <p>He is an awful law enforcement officer.</p>
            <p>
              Agent Utah lets criminals escape multiple times, one of which
              while firing his weapon into the air, without a care for
              potentially harming civilians. This is a guy who was a 100%
              marksman while training at the academy, there's no excuse for not
              hitting his target, his feelings for the perp shouldn't influence
              the shot. Based on a hunch, he absolutely ruins a three month
              undercover operation for the DEA. Johnny's an extremely poor judge
              of character and unknowingly befriends the leader of the bank
              robbing gang he's supposed to be investigating. That's if he is
              investigating at all instead of being easily distracted out
              catching tubes rather than bad guys.
            </p>
            <div className="image-and-caption">
              <img
                src={HappyCommunity}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">BFFL</p>
            </div>
            <p>
              When it's all said and done, after months of hunting and tracking,
              Johnny Utah would rather have the respect of a straight up
              murderer than bring him to justice. A real Keanu character ending
              would have seen Bodhi in cuffs, helplessly watching the massive
              swells crash down from the rear window of a black and white as
              he's hauled off to jail.
            </p>
            <div className="image-and-caption">
              <img
                src={Funeral}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">"Hello Fellow Surfers"</p>
            </div>
            <p>
              From rabid annual viewers, sometimes catch it casually on TV
              people, or that guy who saw it that one time but remembers, “...it
              was pretty awesome”, either way no one is really talking about
              𝘗𝘰𝘪𝘯𝘵 𝘉𝘳𝘦𝘢𝘬 anymore so I guess that's why this is a pretty Cold
              Take.
            </p>
          </div>{" "}
          <Link to="/oldnotgood">
            <div className="readMoreContainer">
              <p className="readMoreHeader">Read next:</p>
              <p className="readMoreContent">Old ≠ Good</p>
              <p className="underscore"></p>
            </div>
          </Link>
          <ShareSquare
            description={
              "Check out this article from FilmSlobs! Johnny Utah is a Piece of Shit."
            }
          />
          <div>
            <Link to="/email">
              <NotificationBell />
            </Link>
          </div>
        </div>
        <div className="relatedSidebar">
          <p className="seeAlso">You may also like ...</p>
          <Link to="/hackiswack">
            <div className="image-and-caption">
              <img
                src={Hacking}
                className="article-image-sidebar"
                alt={"Player Avatar"}
              />
              <p id="sidebar-image-caption">Hack is Wack</p>
            </div>
          </Link>
          <Link to="/mostdisappointing">
            <div className="image-and-caption">
              <img
                src={PhasmaMain}
                className="article-image-sidebar"
                alt={"Player Avatar"}
              />
              <p id="sidebar-image-caption">Most Disappointing Badasses</p>
            </div>
          </Link>
          <Link to="/oldnotgood">
            <div className="image-and-caption">
              <img
                src={arabia}
                className="article-image-sidebar"
                alt={"Player Avatar"}
              />
              <p id="sidebar-image-caption">Old ≠ Good</p>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Johnny;
