import React from "react";
import { Link } from "react-router-dom";
import cinderella from "../../src/pictures/lucky-you/cinderella-burn.jpeg";
import iceman from "../../src/pictures/lucky-you/iceman.jpeg";
import Jerkstore from "../../src/topfive-pictures/jerkstore.png";
import cyrusTongue from "../../src/pictures/con-air/cyrus.jpg";
import falzon from "../../src/pictures/con-air/falzon.jpg";
import tombstone from "../../src/topfive-pictures/grissom-tomb.jpeg";
import weddingSinger2 from "../../src/topfive-pictures/wedding-singer-2.jpeg";
import weddingSinger5 from "../../src/topfive-pictures/wedding-singer-5.jpeg";
import Race1 from "../../src/topfive-pictures/race-1.jpg";
import Race2 from "../../src/topfive-pictures/race-2.jpg";
import Taxi1 from "../../src/topfive-pictures/taxi-1.jpg";
import Taxi2 from "../../src/topfive-pictures/taxi-2.jpg";
import Rolemodels2 from "../../src/topfive-pictures/rm2.jpg";
import Rolemodels3 from "../../src/topfive-pictures/rm3.jpg";
import Wheelchair from "../../src/topfive-pictures/wheelchair.png";
import hackiswack from "../../src/grabbag-pictures/hacking.png";
import dramaischeap from "../../src/grabbag-pictures/drama.jpeg";
import arabia from "../../src/coldtake-pictures/arabia.jpeg";
import ShareCircle from "../ShareCircle.js";
import ShareSquare from "../ShareSquare";
import NotificationBell from "../NotificationBell";

function BestZingers() {
  return (
    <div>
      <div className="currentRoute">
        <Link to="/articles">
          <span className="underline">Articles</span>
        </Link>{" "}
        &gt;{" "}
        <Link to="writingtopFive">
          <span className="underline">Top 5 Lists</span>
        </Link>{" "}
        &gt; Best Zingers from Minor Characters
      </div>
      <div className="articleContentContainer">
        {" "}
        <div class="article-container">
          <div class="article-container-header">
            <p>Best Zingers from Minor Characters</p>
          </div>
          <div className="published">PUBLISHED Jul 3, 2023</div>
          <div className="authorName">
            BY{" "}
            <Link to="/dean">
              <span className="green" id="author-name">
                Dean
              </span>
            </Link>
            <ShareCircle
              description={
                "Check out this article from FilmSlobs! Top 5 Zingers from Minor Characters"
              }
            />
          </div>
          <div class="article-container-body">
            <p>
              The main character - the chosen one, the hero, the alpha. It's
              rare to see them getting insulted, let alone by some obscure
              schlub, but sometimes the universe has a funny way of showing us
              who's boss. In those moments, the minor character steps up to the
              plate and delivers a crushing insult that leaves the main
              character reeling. It's a classic{" "}
              <span className="green">David vs. Goliath</span> battle, except in
              this case David's weapon is a{" "}
              <span className="green">zinger</span>, and Goliath's weakness is
              his ego.{" "}
            </p>
            <h2>5.) Lucky You</h2>

            <p>
              Eric Bana crushed opponents left and right on his way to the World
              Series of Poker final table. These losers didn't have names, they
              didn't have faces, and they sure as hell didn't have comebacks ...
              until Michael Shannon showed up.
            </p>
            <div className="image-and-caption">
              <img
                src={iceman}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">
                This is from <i>The Iceman</i>, but dude is a stone-cold killer
                in <i>Lucky You</i>, too.
              </p>
            </div>

            <p>
              At the conclusion of a nail-biting all-in hand, Bana wins the pot
              and gloats, "Sorry I won’t be seeing you at the big dance". But
              after a misdeal reveals he actually lost, Shannon stands up and
              says,{" "}
              <span className="green">
                "Guess you won’t be needing to press your dress quite yet,
                Cinderella."
              </span>
            </p>

            <div className="image-and-caption">
              <img
                src={cinderella}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">Behold my works, ye mighty, and despair.</p>
            </div>
            <p>
              He zinged Bana so hard that they don't even show his face - the
              scene just cuts like a bad breakup voicemail.
            </p>
            <p>
              <strong>Writers Note:</strong> After clawing his way back to the
              tournament, Bana did have a decent retort. When he sat down at the
              table, Shannon acted surprised and said "Look who's here!", to
              which Bana fired back{" "}
              <span className="green">
                "I managed to get my dress pressed after all"
              </span>
              . That's a slick line, but the moment was long gone. He comes off
              like a loser who spent hours drafting a comeback that still falls
              flat.
            </p>

            <div className="image-and-caption">
              <img
                src={Jerkstore}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption"></p>
            </div>
            <h2>4.) The Wedding Singer</h2>
            <p>
              Usually when the hero and the villain have it out, the villain at
              least takes a couple of hits. But not in the Wedding Singer, where
              our 'hero' gets his ass kicked from pillar to post.
            </p>

            <p>
              Robbie catches Glenn being unfaithful during a 'pre-bachelor
              party' party and guilts him for being a sleazeball, even though
              Robbie himself has spent the whole damn movie trying to steal
              Glenn's fiancée.
            </p>
            <p>
              Not having <em>any of it</em>, Glenn hits Robbie with The Wedding
              Zinger:{" "}
              <span className="green">
                "Hey, asswipe. Don't go snitching to Julia about this. I know
                you got some little crush on her, but you gotta face the facts.
                She'd rather go to bed with a real man - not some poor singing
                orphan"
              </span>
              .
            </p>
            <div className="image-and-caption">
              <img
                src={weddingSinger2}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">He said with a carefree smirk</p>
            </div>
            <p>
              After getting decimated in the verbal realm, Robbie tries his hand
              in the physical - which proves more destructive than Japan calling
              Hiroshima a fluke. Not only does Glenn knock him flat on his ass
              with one punch, but he sardonically quips,{" "}
              <span className="green">
                "Hey! Why don't you write a song about this. You can call it 'I
                Got Punched in the Nose, For Sticking My Face in Other People's
                Business!'"
              </span>
              .
            </p>
            <div className="image-and-caption">
              <img
                src={weddingSinger5}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">
                'Glenn's Greatest Hit' would be more appropriate
              </p>
            </div>
            <p>
              As Robbie writhes in utter humiliation, Glenn's entire entourage
              erupts in laughter and saunters off.
            </p>

            <h2>3.) Days of Thunder</h2>
            <p>
              With his charming grin and perfectly coiffed hair, Tom Cruise in
              "Days of Thunder" is like a lightning bolt wrapped in a denim
              jacket. Dude oozes confidence as he zooms around the track, and
              given his well-documented{" "}
              <span className="green">need for speed</span> it's clear that no
              one can slow him down, right? Well,
              <span className="green"> pump the brakes</span>.
            </p>
            <p>
              While dropping Nicole Kidman off at her hotel, Cruise parks for a
              whole ten seconds before a nobody taxi driver rides straight up
              his ass.
            </p>

            <div className="image-and-caption">
              <img
                src={Taxi1}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">"Fuckin' MOVE!"</p>
            </div>
            <p>
              Travis Bickle doesn't give a shit about grease lightning here. He
              doesn't see a high-octane hotshot; he see's some idiot blocking
              his lane. Forget the fact that{" "}
              <span className="green">the parking lot is deserted</span> and he
              <span className="green"> doesn't even have a fare</span> - this
              cabby's GOTTA GO. After some frustrated honking and furious hand
              gestures to no avail, Taxi Terry does what any sensible person
              would do: he rams Tom's car.
            </p>

            <div className="image-and-caption">
              <img
                src={Taxi2}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">"Rubbin', son, is racin'"</p>
            </div>
            <p>
              Cruise retaliates by ramming back, but our hero burns out and
              zooms right past him - proving he could have effortlessly
              maneuvered around Tom from the beginning but{" "}
              <span className="green">consciously decided to fuck him up</span>.
              And fuck him up he did - he left Cruise in the dust as Nicole
              Kidman shouted in her shrill accent, "Let me out of the caaa. Let
              me out of the CÄÄÄÄ!".
            </p>
            <p>
              Dude came, saw, conquered, and disappeared. That's why, although
              non-verbal, this still counts as a zinger. And we're getting off
              topic now, but this movie could have been called 'Days of Blunder'
              as Cruise holistically sucked at racing. He lost almost every race
              he was in. Hell, even 59 year old Robert Duvall smoked him in
              <span className="green"> multiple</span> foot-races.
            </p>
            <div className="image-and-caption">
              <img
                src={Race1}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">Exhibit A</p>
            </div>
            <div className="image-and-caption">
              <img
                src={Race2}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">Exhibit B. Duvall could NOT be happier.</p>
            </div>
            <p>
              Fuckin' guy even lost a{" "}
              <span className="green">wheelchair race</span>.{" "}
            </p>
            <div className="image-and-caption">
              <img
                src={Wheelchair}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">He never stood a chance</p>
            </div>

            <h2>2.) Role Models</h2>
            <p>
              Danny and Wheeler are{" "}
              <span className="green">smooth-talking</span> dudes in the energy
              drink business. When they're not selling nuclear horse piss to
              children for six bucks a can, they're rocking it at Venice's
              coolest parties and regaling everyone with their{" "}
              <span className="green">quick wit</span> and{" "}
              <span className="green">slick remarks.</span>
            </p>
            <p>
              Their verbal dexterity is on display from the get-go. Within the
              first minute of the movie, a posse of cool kids dare to mock the
              Minotaur costume, and Danny and Wheeler respond with a tag team
              that hits like a bodyslam.
            </p>

            {/* <div className="image-and-caption">
              <img
                src={Rolemodels}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">Here's an image caption</p>
            </div> */}
            <div className="image-and-caption">
              <img
                src={Rolemodels2}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">
                <strong>Danny: </strong>Oh it's not a cow - it's a minotaur; a
                creature of myth. And he got this one out of your moms closet.{" "}
                <strong>Wheeler: </strong>She let me keep it after I fucked her.
              </p>
            </div>
            <p>
              The young punks learned the hard way that if you mess with the
              bull, you get the horns. We see several other quippy-quip
              wisecracks go unanswered (e.g. the Venti coffee scene) before
              Danny and Wheeler finally meet their match.
            </p>
            <p>
              After witnessing their truck being towed, they run and yell,
              "Woah! Dude, I thought we could park here!". To which theretofore
              and thereafter unseen security guard (Louis CK) asserts,{" "}
              <span className="green">
                "and you thought wrong, you furry faggot"
              </span>
              .
            </p>
            <div className="image-and-caption">
              <img
                src={Rolemodels3}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">
                The hiss is not from the hydraulic lift, but from their egos
                deflating.
              </p>
            </div>
            <p>
              The fight was over before it began. There was no comeback. It was
              such a potent and unexpected zinger that it sparked a meltdown in
              Danny, resulting in both characters getting arrested. Just goes to
              show that even the mightiest heroes are not immune to the
              merciless sting of a minor character.
            </p>

            <h2>1.) Con Air</h2>
            <p>
              Cyrus the Virus is a criminal whose propensity for violence is
              exceeded only by his intellect. While incarcerated he earned two
              degrees (including his Juris Doctorate) and masterminded The
              Jailbird hijacking.
            </p>
            <p>
              This is a man who does not lose verbal exchanges. Be it the US
              Marshalls, the DEA, or even Danny Trejo (speaking Spanish, no
              less), Cyrus is never at a loss for words.
            </p>
            <div className="image-and-caption">
              <img
                src={cyrusTongue}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">Look at this silver-tongued bastard.</p>
            </div>
            <p>
              So when Guard Falzon walks past his cell, Cyrus decides to have a
              bit of fun at this glorified rent-a-cop's expense. There's no way
              this key-jangling dolt is capable of witty repartee, right?
            </p>
            <div className="image-and-caption">
              <img
                src={falzon}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">So you've chosen death.</p>
            </div>
            <p>
              Cyrus says, “Stewardess. Stewardess. What’s the in-flight movie
              today?”, and Guard Falzon summarily executes him.{" "}
              <span className="green">
                "I think you'll like it, Cyrus. It's called{" "}
                <em>'I'll Never Make Love to a Woman on the Beach Again'</em>,
                and it's preceded by the award-winning short,{" "}
                <em>'No More Steak for Me, Ever'</em>
              </span>
              .
            </p>

            <div className="image-and-caption">
              <img
                src={tombstone}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">Hell will seem cold after that torching</p>
            </div>
            <p>
              The once glib orator is reduced to muttering a vulgar comment
              under his breath while Falzon struts away laughing. Without a
              doubt, Cyrus got crushed harder here than in the junkyard scene.
            </p>
          </div>
          <Link to="/fakemovies">
            <div className="readMoreContainer">
              <p className="readMoreHeader">Read next:</p>
              <p className="readMoreContent">
                Fake Movies We Actually Want to See
              </p>
              <p className="underscore"></p>
            </div>
          </Link>
          <ShareSquare
            description={
              "Check out this article from FilmSlobs! Top 5 Zingers from Minor Characters"
            }
          />{" "}
          <div>
            <Link to="/email">
              <NotificationBell />
            </Link>
          </div>
        </div>
        <div className="relatedSidebar">
          <p className="seeAlso">You may also like ...</p>
          <Link to="/dramaischeap">
            <div className="image-and-caption">
              <img
                src={dramaischeap}
                className="article-image-sidebar"
                alt={"Player Avatar"}
              />
              <p id="sidebar-image-caption">Drama is Cheap</p>
            </div>
          </Link>
          <Link to="/oldnotgood">
            <div className="image-and-caption">
              <img
                src={arabia}
                className="article-image-sidebar"
                alt={"Player Avatar"}
              />
              <p id="sidebar-image-caption">Old ≠ Good</p>
            </div>
          </Link>
          <Link to="/hackiswack">
            <div className="image-and-caption">
              <img
                src={hackiswack}
                className="article-image-sidebar"
                alt={"Player Avatar"}
              />
              <p id="sidebar-image-caption">Hack is Wack</p>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default BestZingers;
