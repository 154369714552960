import React from "react";
import Armwrestle from "../src/pictures/predator/armwrestle.webp";

// import NewsletterEmail from "./NewsletterEmail";

function Support() {
  return (
    <div>
      <section>
        <div className="aboutTextContainer" style={{ marginTop: "0px" }}>
          <h1 className="supportTitle">
            Be <span className="green">Awesome</span>
          </h1>
          <div>
            <img
              src={Armwrestle}
              className="article-image"
              alt={"Player Avatar"}
              style={{ objectFit: "cover", paddingTop: "0px" }}
            />
          </div>
          <p style={{ lineHeight: " 30px" }}>
            {" "}
            Looking for a way to support us without having to sacrifice a kidney
            on the black market? Then consider{" "}
            <span className="green">buying us a beer!</span> It's the easy and
            affordable way to show your love and help keep us motivated as we
            work hard to bring you top-notch content. And best of all, it's
            <span className="green"> completely optional!</span> So if you're
            feeling generous and want to help fuel our creative juices and maybe
            get a little buzz of your own, click that button and buy us a cold
            one. We'll be forever grateful and slightly less misanthropic.
            Cheers!
          </p>
        </div>
      </section>
      <div>
        <a
          href="https://www.buymeacoffee.com/filmslobs"
          target="_blank"
          rel="noreferrer noopener"
        >
          <button className="beerButton">🍺 Buy us a beer!</button>
        </a>
      </div>
      <div className="contactLogoContainer">
        <div className="aboutTextContainer"></div>
      </div>
    </div>
  );
}

export default Support;
