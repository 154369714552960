import React from "react";
import GambolMain from "../topfive-pictures/gambol-main.jpeg";
import GambolKnife from "../topfive-pictures/gambol-knife.jpeg";
import PhasmaMain from "../topfive-pictures/phasma-main.jpeg";
import PhasmaBright from "../topfive-pictures/phasma-bright.jpeg";
import BillyMain from "../topfive-pictures/billy-main.jpeg";
import BillyLaugh from "../topfive-pictures/billy-laugh.jpeg";
import Areoax from "../topfive-pictures/areo-ax.jpeg";
import AreoSmoke from "../topfive-pictures/areo-smoke.jpeg";
import disappointed from "../topfive-pictures/disappointed.jpeg";
import { Link } from "react-router-dom";
import ShareCircle from "../ShareCircle.js";
import ShareSquare from "../ShareSquare";
import lucabrasi from "../topfive-pictures/luca-brasi.jpeg";
import lucadeadfish from "../topfive-pictures/lucadeadfish.jpeg";
import koogler from "../topfive-pictures/koogler.jpeg";
import odevideo from "../grabbag-pictures/odethumbnail-black.jpeg";
import Community from "../coldtake-pictures/community.jpeg";
import NotificationBell from "../NotificationBell";
import MarquisOne from "../topfive-pictures/marquis-1.jpg";
import MarquisTwo from "../topfive-pictures/marquis-2.jpg";

function MostDisappointing() {
  return (
    <div>
      <div className="currentRoute">
        <Link to="/articles">
          <span className="underline">Articles</span>
        </Link>{" "}
        &gt;{" "}
        <Link to="writingtopFive">
          <span className="underline">Top 5 Lists</span>
        </Link>{" "}
        &gt; Most Disappointing Badasses
      </div>
      <div className="articleContentContainer">
        <div class="article-container">
          <div class="article-container-header">
            <p>Most Disappointing Badasses</p>
          </div>
          <div className="published">PUBLISHED Jul 3, 2023</div>
          <div className="authorName">
            BY{" "}
            <p>
              <Link to="/Ashton">
                <span className="green" id="author-name">
                  Ashton
                </span>
              </Link>
            </p>
            <ShareCircle
              description={
                "Check out this article from FilmSlobs! Top 5 Most Disappointing Badasses"
              }
            />
          </div>
          <div class="article-container-body">
            <div className="image-and-caption">
              <img
                src={disappointed}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">Wait a minute, this isn't my world...</p>
            </div>
            <p>
              Often in film and TV we see stories of unlikely characters achieve
              incredible feats. They become who they were meant to be and save
              the day. We’re told not to judge a book by its cover.
            </p>

            <p>
              Other times we have characters who immediately look and seem
              awesome. They have huge build-ups and great backstories, carry
              cool weapons we can’t wait to see kick ass, and deliver bone
              chilling lines.
            </p>
            <p>
              Then there are those who fart in the wind when it comes to actual
              action; ruining what would have been a memorable character. Some
              of these iconic characters' disappointment really boils down to a
              lackluster demise. Either way, don’t judge a book by its cover.
            </p>
            <p>Here are the Most Disappointing Badasses:</p>
            <h2>5.) GAMBOL (The Dark Knight)</h2>
            <div className="image-and-caption">
              <img
                src={GambolMain}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">If looks could kill.</p>
            </div>
            <p>
              Gambol held his own as a mob leader in the Gotham underworld.
              Gaining territory from and then allying with the Chechen gang and
              Italian mafia. Plus his expensive suit couldn’t hide MJW’s
              bodybuilder physique.
            </p>

            <p>
              Ever vigilant with his growing empire, Gambol was the only one who
              saw the Joker for what he was: a clown. His contemporaries
              wouldn’t agree, so fuck ‘em, he took it upon himself to stop the
              lip licking lunatic.
            </p>

            <div className="image-and-caption">
              <img
                src={GambolKnife}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">Time to turn the other cheek.</p>
            </div>
            <p>
              All for nothing because he just learns about the history of some
              scar tissue, puts up ZERO fight, and a swift Hefty bag over his
              head ends the scene. Even his henchman fell for that stupid magic
              trick.
            </p>
            <h2>4.) PHASMA (Star Wars: The Force Awakens)</h2>
            <div className="image-and-caption">
              <img
                src={PhasmaMain}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">
                It isn't just for fashion, it also functions as a sweet fuckin'
                cape.
              </p>
            </div>
            <p>
              Captain Phasma the only Silver Storm Trooper. She wears a cool red
              and black cape, carries a bitching gunsmoke blaster, and spits ice
              through her intercom. When ordered to destroy a village of
              treasonous peasants she immediately begins the deadly countdown.
            </p>

            <p>
              Clearly Phasma has earned that armor and rank for a reason. Can’t
              they learn from Star Trek and hire some red shirt losers to show
              off the “bad guys” talents a bit? Let’s see her smoke some scum!
            </p>

            <div className="image-and-caption">
              <img
                src={PhasmaBright}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">
                "Garbage mashers on the Detention Level are still shut down,
                right?"
              </p>
            </div>
            <p>
              Rather than laser blasting fools she just kind of meanders in the
              way of the plot for half a second or so not giving any thought to
              being an obstacle to the terrorists. Despite her stellar sheen &
              polish-job the rebels remain unblinded and, in a lame call-back
              joke, this shiny turd lands where she belongs.
            </p>
            <h2>3.) LUCA BRASI (The Godfather)</h2>
            <div className="image-and-caption">
              <img
                src={lucabrasi}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">"Don...Uhh...Vito...Hello...Uhh..."</p>
            </div>
            <p>
              The personal strong-arm enforcer to Don Vito, he cut the head off
              a $60,000 stud, killed six men, and personally put an end to the
              “Olive Oil War”. There's good reason he’s feared by everyone in
              the other four families; Luca Brasi is a badass motherfucker.
            </p>

            <p>
              Kay is rightfully horrified by this giant monster mumbling in the
              corner and that’s before she finds out his job duties entail
              blackmail, extortion, and planning first degree murder. Vito
              Corleone is only afraid of two things: the other families and
              Brasi.
            </p>
            <p>
              So why not send Luca to get some intel on The Turk by having him
              pretend that he's unhappy with Don Vito's management style?
              Because Brasi can barely string two sentences together without
              ample practice and the entire city is aware of his blinding
              loyalty to the Corleone Family.
            </p>

            <div className="image-and-caption">
              <img
                src={lucadeadfish}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">
                It's a metaphor for how he puts up a fight.
              </p>
            </div>
            <p>
              Unsurprisingly Luca isn’t the best at selling his dissatisfaction.
              His fate is sealed, you can see the attack coming a mile away. The
              good news is that now we’ll also get to see Mount Brasuvius rain
              hellfire down on the other goons!
            </p>
            <p>
              Instead Luca decides that a knife in the (magically switching)
              hand and a soft garrote were enough to make standing completely
              still the more dignified way to die. Ya know… rather than use his
              incredible size and strength to squirm, shake, kick, headbutt,
              heel to the groin, or attempt any struggle what-so-ever.
            </p>
            <h2>2.) VINCENT BISSET de GRAMONT (John Wick: Chapter 4)</h2>
            <div className="image-and-caption">
              <img
                src={MarquisOne}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">
                "𝙇𝙚 whomever kills Jean Wick wins 𝙢𝙤𝙣 𝙗𝙖𝙡𝙡𝙤𝙣."
              </p>
            </div>
            <p>
              In a universe filled with over the top badassery, John Wick has
              seen his fair share of one-upping bad guys who constantly out-do
              eachother for an end seat at The High Table. Facing off against
              this former assassin, turned former dog dad, who has the best
              headshot count in cinema history is no small feat. It only makes
              sense to have extremely formidable opponents worthy of Wick’s
              wrath.
            </p>

            <p>
              Throughout his dark (as in visually) and violent (as in bloody as
              fuck) run we find John Wick Chapter’s One & Two fighting the mob &
              mafia respectively. Chapter Three takes a turn against the entire
              assassin's creed with a potential follow-up to fill in some gaps.{" "}
              <i>John Wick: Chapter 4</i> keeps the pace of the series with
              almost non-stop action and a real motivation for Baba Yaga’s
              return, the antagonist is sure to be a truly awful opponent.
            </p>

            <div className="image-and-caption">
              <img
                src={MarquisTwo}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">"There's 𝙣𝙤𝙣 way, I had 𝙡𝙚 cool line!"</p>
            </div>
            <p>
              Unfortunately the Marquis de Gramont is awful in all the wrong
              ways. Vincent is the poster-boy for corporate middle management
              with an endless budget on a project he knows he’ll never complete.
            </p>
            <p>
              Despite being dressed for the occasion our guy doesn’t kill{" "}
              <span className="green">IT</span>. At no point did it seem like
              the Marquis was really in control, instead blindly leading his
              nobody staff. Especially at the end. Facing John Wick in a duel
              with pistols is like taking on Steph Curry in a 3-point shootout.
              You’re gonna lose, simple as that.
            </p>
            <p>
              At least they went against the stereotypical Frenchman as a
              whiney, incompetent, manchild who is afraid of hard work.
            </p>
            <h2>TV HONORABLE MENTION: AREO HOTAH (Game of Thrones)</h2>
            <div className="image-and-caption">
              <img
                src={Areoax}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">If I lost a hand, I'd gain a spear.</p>
            </div>
            <p>
              The Captain of the Guards to House Martell. This dude is huge, he
              wields a massive axe-spear hybrid, and doesn’t take shit from
              anyone. When Areo flattens Bronn's crocodile snout, we get a
              glimpse of the anger boiling under that Valyrian-steely cold
              demeanor and we wanna see him weild it.
            </p>

            <p>
              The guy tongue lashes Jaime Lannister, who is second in wise-ass
              comebacks only to his brother Tyrion. The back-and-forth builds up
              anticipation for us to watch Areo Hotah in a "good fight” with
              someone who is whole and knows what they’re doing with a blade.
            </p>

            <div className="image-and-caption">
              <img
                src={AreoSmoke}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">
                "It's nice knowing you have Dorne's back!"
              </p>
            </div>
            <p>And then... He just gets stabbed through the spine?!</p>
            <p>
              We waited the entire fifth season to watch the noxious Norvoshi
              explode into a blood soaked rampage against the Lannisters and
              Snake Sis's. Only for him to fissle out, as much of the series
              did, at the beginning of the sixth season.
            </p>
            <p>
              Captain Hotah deserved an epic fight even if he needed to lose it
              to progress a storyline which went nowhere. Barristan Selmy died
              like the actual badass he was… Why couldn’t Areo have gone out
              swinging his axe?
            </p>
            <p>
              He talked a big talk and we never got to see him, or his Prince,
              walk the walk.
            </p>
            <h2>1.) BILLY SOLE (Predator)</h2>
            <div className="image-and-caption">
              <img
                src={BillyMain}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">
                "If I'm gonna die, might as well take that thing with me."
              </p>
            </div>
            <p>
              The expert among experts in tracking, hunting, and killing. Billy
              understood the Predator better than anyone else on a team full of
              CIA spec-ops commandos; each more grizzled and manly than the
              last.
            </p>

            <p>
              After seeing a few of his buddies get mutilated, Billy decides
              that he’s hardcore enough to face the murdering monster alone and
              even does a sweet chest cutting taunt. We’re definitely building
              up to a spectacular showdown from this bloodthirsty warrior.
            </p>

            <div className="image-and-caption">
              <img
                src={BillyLaugh}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">SIKE!</p>
            </div>
            <p>
              Oh nope, off screen death and there’s his limp sack of skin. What
              a waste of a cool character.
            </p>
            <p>
              <strong>Runner-Up:</strong> Karl Honig (Johnny Mnemonic)
            </p>
          </div>{" "}
          <Link to="/bestzingers">
            <div className="readMoreContainer">
              <p className="readMoreHeader">Read next:</p>
              <p className="readMoreContent">
                Best Zingers from Minor Characters
              </p>
              <p className="underscore"></p>
            </div>
          </Link>
          <ShareSquare
            description={
              "Check out this article from FilmSlobs! Top 5 Most Disappointing Badasses"
            }
          />
          <div>
            <Link to="/email">
              <NotificationBell />
            </Link>
          </div>
        </div>
        <div className="relatedSidebar">
          <p className="seeAlso">You may also like ...</p>
          <Link to="/odevideostore">
            <div className="image-and-caption">
              <img
                src={odevideo}
                className="article-image-sidebar"
                alt={"Player Avatar"}
              />
              <p id="sidebar-image-caption">Ode to the Video Store</p>
            </div>
          </Link>
          <Link to="/fakemovies">
            <div className="image-and-caption">
              <img
                src={koogler}
                className="article-image-sidebar"
                alt={"Player Avatar"}
              />
              <p id="sidebar-image-caption">
                Fake Movies We Actually Want to See
              </p>
            </div>
          </Link>
          <Link to="/community">
            <div className="image-and-caption">
              <img
                src={Community}
                className="article-image-sidebar"
                alt={"Player Avatar"}
              />
              <p id="sidebar-image-caption">Lamest Community Episode</p>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default MostDisappointing;
