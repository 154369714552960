import React from "react";
import Modal from "@mui/material/Modal";

export default function SimpleModal(props) {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  let movieTitle = `https://www.google.com/search?q=where+to+watch+${props.name}`;

  let [clicked, setClick] = React.useState(false);
  let [movieName, setMovieName] = React.useState("");
  // eslint-disable-next-line

  let getMovieName = () => {
    setClick(!clicked);
    setMovieName((movieName = props.name));
    console.log("clicked", props.name);
  };

  let body = (
    <div className="modal-notRated">
      <div className="notRatedTitle" title={props.name}>
        {props.name}
      </div>

      <div className="img" onClick={getMovieName}>
        <img className="img-img" src={props.poster} alt="movie cover"></img>

        <a target="_blank" rel="noopener noreferrer" href={movieTitle}>
          <div className="img-overlay">
            <div className="img-title">Watch Me!</div>
            <p className="img-description">Click to find where</p>
          </div>
        </a>
      </div>

      <div className="modal-filmInfo-notRated">
        <div className="modal-mpaa-rating">{props.rated}</div>
        <div className="modal-runtime">
          <i className="far fa-clock"></i>
          {props.runtime}
        </div>
        <div className="modal-year">{props.year}</div>
        <div className="modal-rating">
          <span role="img" aria-label="beer">
            🍺
          </span>
          {props.rating}
          <span className="outOf">/6</span>
          <span style={{ display: "none" }}>
            {props.genre}
            {props.subgenre}
          </span>
        </div>
      </div>
    </div>
  );

  return (
    <div>
      <img
        onClick={handleOpen}
        src={props.poster}
        alt="Movie Poster"
        className="moviePosters"
        title={props.name}
      />

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.55)" }}
      >
        {body}
      </Modal>
    </div>
  );
}
