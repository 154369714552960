import { useState } from "react";
import Dolph from "../src/homepage-pictures/dolph.jpeg";
import Busey from "../src/homepage-pictures/nutbag-busey.jpeg";
import Marty from "../src/homepage-pictures/mcfly.jpeg";

function Testimonial(props) {
  const { text, author, image } = props;
  return (
    <div className="grayBackground">
      <div className="drDolphContainer">
        <img className="dolph" src={image} alt={"actor"}></img>
      </div>
      <div className="grayBackgroundInner">
        <span className="chevron left" onClick={props.onLeft}></span>
        <p>{text}</p>
        <span className="chevron right" onClick={props.onRight}></span>
      </div>
      <div className="drDolphContainer">
        <p className="drDolph">- {author}</p>
      </div>
    </div>
  );
}

export default function App() {
  const testimonials = [
    {
      text: "Holy shit. These guys are the best. When I want to watch a movie, FilmSlobs is the only source I trust.",
      author: "DR. DOLPH LUNDGREN",
      image: Dolph,
    },
    {
      text: "FilmSlobs is the finest discovery since the Flux Capacitor.",
      author: "MARTY MCFLY",
      image: Marty,
    },
    {
      text: "I've got a muskrat that can turn a flapjack into a spaceship faster than a hot rod in a lightning storm",
      author: "GARY BUSEY",
      image: Busey,
    },
  ];

  const [activeIndex, setActiveIndex] = useState(0);

  const handleLeft = () => {
    setActiveIndex((prevIndex) =>
      prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1
    );
  };

  const handleRight = () => {
    setActiveIndex((prevIndex) =>
      prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1
    );
  };

  const activeTestimonial = testimonials[activeIndex];

  return (
    <div className="App">
      <Testimonial
        text={activeTestimonial.text}
        author={activeTestimonial.author}
        image={activeTestimonial.image}
        onLeft={handleLeft}
        onRight={handleRight}
      />
    </div>
  );
}
