import React from "react";
import Deathmatch from "../src/pictures/FSLogo/deathmatch.jpeg";
import Beer from "../src/pictures/FSLogo/beer.png";
import Schwarzy1 from "../src/pictures/FSLogo/schwarzy-1.jpeg";
import Sly from "../src/pictures/FSLogo/sly.jpeg";
import Fade from "react-reveal/Fade";

function Games() {
  return (
    <div>
      <div className="page-wrapper">
        <div className="games-wrapper">
          <header>
            <img
              src={Deathmatch}
              className="games-background"
              alt={"Background Logo"}
            />
            {/* <img src={Beer} className="games-foreground" alt={"Background Logo"} /> */}

            <h1 className="games-title">GAMES</h1>
          </header>
          <section>
            <div className="movieGameTextContainer">
              <Fade bottom>
                <p>
                  Let's start with a classic. <span className="green">THE</span>{" "}
                  classic.
                </p>

                <br></br>

                <h1 class="movieGameTitle" style={{ fontSize: "80px" }}>
                  The <span className="green">MOVIE</span> Game
                </h1>

                <br></br>

                <p>
                  This game is like H-O-R-S-E for movie-lovers. Players take
                  turns naming a movie, an actor/actress from that movie, and
                  then another movie the actor/actress has been in. For example,
                  "Dumb and Dumber", "Jim Carrey", "Ace Ventura: Pet Detective".
                </p>

                <br></br>

                <p>
                  It continues in this{" "}
                  <span className="green">movie-actor-movie</span> pattern until
                  someone gets stumped. If a player can't come up with an
                  answer, they get an 'M'. If they get stumped five times, they
                  will have 'M-O-V-I-E' and they're out of the game. Last person
                  standing wins.
                </p>

                <br></br>

                <p>
                  The game is ideal with a group of 4-6, but it only takes 2 to
                  play. For example ...
                </p>

                <br></br>

                <div className="movieGameContainer-left">
                  <img
                    src={Schwarzy1}
                    className="movieGameAvatar"
                    alt={"Player Avatar"}
                  />
                  <p>Oceans 11! </p>
                </div>

                <br></br>

                <div className="movieGameContainer-right">
                  <p>Brad Pitt</p>
                  <img
                    src={Sly}
                    className="movieGameAvatar"
                    alt={"Player Avatar"}
                  />
                  <br></br>
                </div>

                <br></br>

                <div className="movieGameContainer-left">
                  <img
                    src={Schwarzy1}
                    className="movieGameAvatar"
                    alt={"Player Avatar"}
                  />
                  <p>Fight Club!</p>
                </div>

                <br></br>

                <div className="movieGameContainer-right">
                  <p>Helena Bonham Carter</p>
                  <img
                    src={Sly}
                    className="movieGameAvatar"
                    alt={"Player Avatar"}
                  />
                </div>

                <br></br>

                <div className="movieGameContainer-left">
                  <img
                    src={Schwarzy1}
                    className="movieGameAvatar"
                    alt={"Player Avatar"}
                  />
                  <p>Oh crap. I'm drawing a blank - I give up.</p>
                </div>

                <br></br>

                <div className="movieGameContainer-right">
                  <p>Dark Shadows</p>
                  <img
                    src={Sly}
                    className="movieGameAvatar"
                    alt={"Player Avatar"}
                  />
                </div>

                <br></br>

                <div className="movieGameContainer-left">
                  <img
                    src={Schwarzy1}
                    className="movieGameAvatar"
                    alt={"Player Avatar"}
                  />
                  <p>
                    Damn! All right. I've got 'M'. Let's start fresh --
                    Independence Day.{" "}
                  </p>
                </div>

                <br></br>

                <p>
                  The cycle then begins again. By the way, you don't{" "}
                  <em>need</em> to start with a movie - you can also begin with
                  an actor. Whatever floats your boat.
                </p>
              </Fade>
              <br></br>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default Games;
