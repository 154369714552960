import React from "react";

function ShareSquare({ description }) {
  const url = window.location.href;

  return (
    <>
      <div className="social-media">
        <a
          href={`https://www.facebook.com/sharer/sharer.php?u=${url}`}
          target="_blank"
          rel="noreferrer"
        >
          <button className="social-button" id="social-facebook">
            {" "}
            <i className="fab fa-facebook-square" id="facebook-square"></i>
            Share
          </button>
        </a>

        <a
          href={`https://twitter.com/intent/tweet?url=${url}&text=${encodeURI(
            description
          )}`}
          target="_blank"
          rel="noreferrer"
        >
          <button className="social-button" id="social-twitter">
            {" "}
            <i className="fab fa-twitter-square" id="twitter-square"></i>
            Tweet
          </button>
        </a>

        {/* <a
          href={`https://twitter.com/intent/tweet?url=${url}&text=${encodeURI(
            description
          )}`}
          target="_blank"
        > */}
        <button className="social-button" id="social-reddit">
          <i class="fab fa-reddit-square" id="reddit-square"></i>
          Share
        </button>
        {/* </a> */}

        <a
          href={`mailto:info@example.com?&subject=You+have+to+See+this!&cc=&bcc=&body=Check+out+this+site:${url}\n${encodeURI(
            description
          )}`}
          target="_blank"
          rel="noreferrer"
        >
          <button className="social-button" id="social-envelope">
            <i class="fa fa-envelope-square" id="envelope-square"></i>
            Share
          </button>
        </a>
      </div>
    </>
  );
}

export default ShareSquare;
