import React, { useState, useEffect } from "react";
import FSLogoNew from "../src/pictures/FSLogo/slobs-new.png";

function Parallax() {
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    setOffset(window.pageYOffset);
  };

  return (
    <div>
      <div className="parallax-container">
        <div
          className="parallax-background"
          style={{ transform: `translateY(${offset * 0.5}px)` }}
        ></div>
        <div className="parallax-content">
          <div className="parallax-content-inner">
            <img
              src={FSLogoNew}
              alt={"FilmSlobs logo"}
              className="parallax-logo"
            />
          </div>
        </div>
      </div>
      <div className="parallax-content-text">
        <h1
          className="welcome-text"
          style={{ transform: `translateY(-${offset * 0.3}px)` }}
        >
          Welcome!
        </h1>
      </div>
    </div>
  );
}

export default Parallax;
