import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import MovieCard from "./MovieCard";
import movieList from "./MovieList";
import NoActors from "./NoActors";
import ScrollArrow from "./ScrollArrow";

function ActorSearch() {
  let [search, setSearch] = useState(null);

  let reviewedMovies = movieList.filter((movie) => movie.synopsis !== "");

  let items = reviewedMovies

    .filter(
      (movie) =>
        search === null ||
        movie.cast.toLowerCase().includes(search.toLowerCase())
    )
    .map((movie) => {
      return (
        <MovieCard
          key={movie.id}
          name={movie.name}
          poster={movie.poster}
          rating={movie.rating}
          year={movie.year}
          synopsis={movie.synopsis}
          genre={movie.genre}
          subgenre={movie.subgenre}
          runtime={movie.runtime}
          pairs={movie.pairs}
          noPairs={movie.noPairs}
          drinkRecipe={movie.drinkRecipe}
          rated={movie.rated}
          picture1={movie.picture1}
          picture2={movie.picture2}
          picture3={movie.picture3}
          tagline={movie.tagline}
          taglineWriter={movie.taglineWriter}
          bulletOne={movie.bulletOne}
          bulletTwo={movie.bulletTwo}
          bulletThree={movie.bulletThree}
          cast={movie.cast}
        />
      );
    });

  let cond =
    items.length === 0 ? (
      <div className="renderContainer">
        <NoActors />
      </div>
    ) : (
      <div className="renderContainer">{items}</div>
    );

  return (
    <div>
      <div>
        <div className="actorSearchAndGenre">
          <TextField
            id="outlined-basic"
            label="Search"
            variant="outlined"
            className="input"
            placeholder="Type an actor"
            onChange={(e) => setSearch(e.target.value)}
            style={{
              width: "95%",
              maxWidth: "375px",
              marginTop: "4%",
              marginLeft: "2.2%",
              marginBottom: "1%",
              backgroundColor: "white",
            }}
          />

          <div className="genreSign actor">ACTOR SEARCH</div>
        </div>
        <ScrollArrow />
        <div>{cond}</div>
      </div>
    </div>
  );
}

export default ActorSearch;
