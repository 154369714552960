import React from "react";
import GambolMain from "../topfive-pictures/gambol-main.jpeg";
import PhasmaMain from "../topfive-pictures/phasma-main.jpeg";
import BillyMain from "../topfive-pictures/billy-main.jpeg";
import { Link } from "react-router-dom";

import ShareCircle from "../ShareCircle.js";
import ShareSquare from "../ShareSquare";
import Odyssey from "../coldtake-pictures/tvtime.jpeg";
import Babe from "../coldtake-pictures/baymovie.jpg";
import Dark from "../coldtake-pictures/dark.png";
import Old from "../coldtake-pictures/Mokeinternet.png";
import Modelt from "../coldtake-pictures/model-t.jpeg";
import DvdShelf from "../grabbag-pictures/dvdshelf-1.jpeg";

function AssumeSale() {
  return (
    <div>
      <div className="currentRoute">
        <Link to="/articles">
          <span className="underline">Articles</span>
        </Link>{" "}
        &gt;{" "}
        <Link to="writingGrabbag">
          <span className="underline">Grab Bag</span>
        </Link>{" "}
        &gt; Don't Assume the Sale
      </div>
      <div className="articleContentContainer">
        <div class="article-container">
          <div class="article-container-header">
            <p>Don't Assume the Sale</p>
          </div>
          <div className="published">PUBLISHED Dec 5th, 2022</div>
          <div className="authorName">
            BY{" "}
            <p>
              <span className="green" id="author-name">
                Dean
              </span>
            </p>
            <ShareCircle
              description={
                "Check out this article from FilmSlobs! Don't Assume the Sale"
              }
            />
          </div>
          <div class="article-container-body">
            <div className="image-and-caption">
              <img
                src={DvdShelf}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">Gee, I hope this scene lasts 20 minutes.</p>
            </div>
            <p>
              We've all met this guy. The pseudo-pedant who extolls the virtues
              of black and white. The insufferable turd in a corduroy jacket who
              poo-poo's everything that's not drama or made before 1970. The
              pretentious über-douche who gargles old movie's balls in the hope
              of looking sophisticated and intelligent. To that guy, we say:
              fuck you.
            </p>

            <p>
              Old does not automatically equal good - in fact, it usually means
              <strong> bad</strong>. We don't dispute that old movies were
              terrific or groundbreaking <em>for their time</em> - but you'll
              never convince us that they're good by modern standards. The
              Model-T was an extraordinary automobile for 1908, but drive one
              today and you'll be obliterated in a matter of seconds by a sedan
              doing 10mph.
            </p>
            <div className="image-and-caption">
              <img
                src={Modelt}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">
                "Oh, you have <em>airbags?</em> How bourgeois."
              </p>
            </div>
            <p>
              For any film snobs out there, you're undoubtedly seething right
              now. How <strong>dare</strong> we have the audacity to even{" "}
              <em>suggest</em> that old films are anything less than sacrosanct.
              We can already hear you gnashing your translucent teeth and
              poison-penning your response -- some tired horseshit like 'what,
              do you need huge explosions and dizzying levels of violence and
              nudity just to enjoy a movie?'. Nope. Don't need those things. But
              what we <em>do </em>
              need is dialogue or story advancement - not five more minutes of
              watching wind chimes clang.
            </p>
            <div className="image-and-caption">
              <img src={Babe} className="article-image" alt={"Player Avatar"} />
              <p id="image-caption">
                Explosions and nudity <em>are</em> outstanding, though.
              </p>
            </div>

            <p>
              Most old movies are excruciatingly slow and comprised of 60% wide
              shots of
              <em>somewhere</em>, and 40% orchestra. Take Lawrence of Arabia for
              example. It's about 372 hours long, and despite being incredibly
              boring, it won't put you to sleep. Right when you're about to
              drift off, screams from desert people or ear-shattering musical
              scores explode through the speakers.
            </p>
            <p>
              In today's world, it's flat out unacceptable to have wide shots of
              a landscape for minutes at a time with no dialogue - it's like
              watching paint dry. But in 1940, watching paint dry was the
              literal alternative to seeing a movie. People didn't have didn't
              have high-powered computers in their pockets that could contact
              anyone in the world, answer any question ever, or render thousands
              of nudie pictures in a second.
            </p>
            <div className="image-and-caption">
              <img src={Old} className="article-image" alt={"Player Avatar"} />
              <p id="image-caption">
                "Everything here is a click away on the internet."
              </p>
            </div>
            <p>
              Audiences were enthralled in 1968 with the unending ape scene in{" "}
              <em>2001: A Space Odyssey</em> and the 45 minute sequence of
              beeping and booping through the universe as nothing happens. They
              probably also loved how
              <em> Once Upon A Time in the West </em>
              opened with ten minutes of tumbleweeds drifting by and a guy
              blowing flies off his face. We simply can't imagine how anyone
              enjoyed it.
            </p>
            <p>
              "Oh boy, honey! Another 30 minute sequence of silence! I'm on the
              edge of my seat."
            </p>
            <div className="image-and-caption">
              <img
                src={Dark}
                className="article-image"
                alt={"Player Avatar"}
                style={{ objectFit: "scale-down" }}
              />
              <p id="image-caption">This edge</p>
            </div>
            <p>
              The worst part is we <em>want</em> to like these movies. We really
              try to enjoy them, but they're ultimately a test of patience
              instead of an enjoyable diversion. Calling these movies shitty and
              boring doesn't make us stupid or ADD (the go-to arguments of every
              condescending cunt who defends these shit movies), it makes us
              reasonable. We simply can admit when something sucks. Like old
              movies.
            </p>
          </div>{" "}
          <Link to="/oldnotgood">
            <div className="readMoreContainer">
              <p className="readMoreHeader">Read next:</p>
              <p className="readMoreContent">Old ≠ Good</p>
              <p className="underscore"></p>
            </div>
          </Link>
          <ShareSquare
            description={
              "Check out this article from FilmSlobs! Don't Assume the Sale"
            }
          />
        </div>
        <div className="relatedSidebar">
          <p className="seeAlso">You may also like ...</p>

          <div className="image-and-caption">
            <img
              src={BillyMain}
              className="article-image"
              alt={"Player Avatar"}
            />
            <p id="sidebar-image-caption">Here's an image caption</p>
          </div>
          <div className="image-and-caption">
            <img
              src={PhasmaMain}
              className="article-image"
              alt={"Player Avatar"}
            />
            <p id="sidebar-image-caption">Here's an image caption</p>
          </div>
          <div className="image-and-caption">
            <img
              src={GambolMain}
              className="article-image"
              alt={"Player Avatar"}
            />
            <p id="sidebar-image-caption">Here's an image caption</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AssumeSale;
