import React from "react";
import "./App.css";
import Navbar from "./Navbar";
import Footer from "./Footer";

function App() {
  return (
    <div>
      <main>
        <Navbar />
        <Footer />
      </main>
    </div>
  );
}

export default App;
