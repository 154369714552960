import React from "react";

function Share({ description }) {
  const url = window.location.href;

  return (
    <>
      <div className="top-social-icons">
        <a
          href={`https://www.facebook.com/sharer/sharer.php?u=${url}`}
          target="_blank"
          rel="noreferrer"
        >
          <div className="top-social-icon" id="circle-facebook">
            {" "}
            <i className="fab fa-facebook-square"></i>
          </div>
        </a>

        <a
          href={`https://twitter.com/intent/tweet?url=${url}&text=${encodeURI(
            description
          )}`}
          target="_blank"
          rel="noreferrer"
        >
          <div className="top-social-icon" id="circle-twitter">
            {" "}
            <i className="fab fa-twitter-square"></i>
          </div>
        </a>

        <a
          href={`mailto:info@example.com?&subject=You+have+to+See+this!&cc=&bcc=&body=Check+out+this+site:${url}\n${encodeURI(
            description
          )}`}
          target="_blank"
          rel="noreferrer"
        >
          <div className="top-social-icon" id="circle-envelope">
            {" "}
            <i className="fa fa-envelope-square"></i>
          </div>
        </a>
        {/* <a
          href={`https://twitter.com/intent/tweet?url=${url}&text=${encodeURI(
            description
          )}`}
          target="_blank"
        > */}
        <div className="top-social-icon" id="circle-reddit">
          {" "}
          <i className="fab fa-reddit-square"></i>
        </div>
        {/* </a> */}
      </div>
    </>
  );
}

export default Share;
