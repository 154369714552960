import React from "react";
import Sly from "../../src/pictures/FSLogo/sly.jpeg";
import Schwarzy1 from "../../src/pictures/FSLogo/schwarzy-1.jpeg";

function Wannabe() {
  return (
    <div>
      {" "}
      <div class="article-container">
        <div class="article-container-header">
          <p>Top 5 Wannabe Movies</p>
        </div>
        <div class="article-container-body">
          <p>
            Top Dog (Turner & Hooch),  
            The Rift (The Abyss), 
            Gordy (Babe), 
            Deep Impact (Armageddon), 
            Donte's Peak (that other volcano movie), 
            Antz (A Bug's Life)
          </p>
          <p>
            Quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
            commodo consequat.
          </p>
          <div className="image-and-caption">
            <img src={Sly} className="article-image" alt={"Player Avatar"} />
            <p id="image-caption">Here's an image caption</p>
          </div>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum.
          </p>
          <p>
            Quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
            commodo consequat.
          </p>

          <div className="image-and-caption">
            <img
              src={Schwarzy1}
              className="article-image"
              alt={"Player Avatar"}
            />
            <p id="image-caption">Here's an image caption</p>
          </div>
          <p>
            “Why don’t we do the coat check? I’ve never done that before, and
            tonight seems as good a night as any, what with all the heavy
            clothing.”
          </p>
        </div>
      </div>
    </div>
  );
}

export default Wannabe;
