import React from "react";
import Johnny from "../coldtake-pictures/johnnyutah.jpeg";
import cinderella from "../../src/pictures/lucky-you/cinderella-burn.jpeg";
import vader from "../../src/pictures/sequel-wins/vader.jpeg";
import { Link } from "react-router-dom";
import ShareCircle from "../ShareCircle.js";
import ShareSquare from "../ShareSquare";
import Roster from "../grabbag-pictures/AppRoster.png";
import FootballBG from "../grabbag-pictures/football/Football_1.jpg";
import Ampipe1 from "../grabbag-pictures/football/Ampipe-2.jpg";
import Buddy1 from "../grabbag-pictures/football/Buddy-1.jpg";
import Cooper1 from "../grabbag-pictures/football/Cooper-1.jpg";
import Falco1 from "../grabbag-pictures/football/Falco-1.jpg";
import Gorman1 from "../grabbag-pictures/football/Gorman-1.jpg";
import Hanon3 from "../grabbag-pictures/football/Hanon-3.jpg";
import Megget1 from "../grabbag-pictures/football/Megget-1.jpg";
import Papale1 from "../grabbag-pictures/football/Papale-1.jpg";
import Sayers1 from "../grabbag-pictures/football/Sayers-1.jpg";
import Tare1 from "../grabbag-pictures/football/Tare-1.jpg";
import Tidwell1 from "../grabbag-pictures/football/Tidwell-1.jpg";
import Washington1 from "../grabbag-pictures/football/washington-1.jpg";
import NotificationBell from "../NotificationBell";

function FootballSlobs() {
  return (
    <div>
      <div className="currentRoute">
        <Link to="/articles">
          <span className="underline">Articles</span>
        </Link>{" "}
        &gt;{" "}
        <Link to="writingGrabbag">
          <span className="underline">Grab Bag</span>
        </Link>{" "}
        &gt; Football: A Fantasy Life
      </div>
      <div className="articleContentContainer">
        <div class="article-container">
          <div class="article-container-header">
            <p>Football: A Fantasy Life</p>
          </div>
          <div className="published">PUBLISHED Sept 6, 2023</div>
          <div className="authorName">
            BY{" "}
            <p>
              <Link to="/Ashton">
                <span className="green" id="author-name">
                  Ashton
                </span>
              </Link>
            </p>
            <ShareCircle
              description={
                "Check out this article from FilmSlobs! Football: A Fantasy Life."
              }
            />
          </div>
          <div className="article-container-body">
            <div className="image-and-caption">
              <img
                src={FootballBG}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">What a Dream Team...</p>
            </div>
            <p>
              The <span className="green">best</span> time of year is upon us.
              Kids have gone back to school, hot days are reluctantly giving way
              to ever growing cooler nights, and armchair quarterbacks have
              fresh bad takes for all 12 of their Twitter followers: it's
              (fantasy) football season!
            </p>
            <p>
              <h3>The rules to our draft are pretty simple:</h3>
            </p>
            <div style={{ marginLeft: "20px" }}>
              <p>
                1. If they played football in a film or made for TV movie, they
                are eligible.
              </p>
              <p>2. No two movies on the same team.</p>
            </div>
            <p>
              {" "}
              <span className="green">That's it!</span>{" "}
            </p>
            <p>
              After receiving word from League Commisioner Gordon that we landed
              Pick 4 of 12 our plan was set, time to execute.
            </p>
            <div className="image-and-caption">
              <div className="article-image-vertical-div">
                <img
                  src={Roster}
                  className="article-image-vertical"
                  alt={"Player Avatar"}
                />
              </div>
              <p id="image-caption">Don't forget to set your roster!</p>
              <p>
                <h2>FilmSlobs Fantasy Football Draft Breakdown:</h2>
              </p>
              <p>
                <div className="image-and-caption">
                  <img
                    src={Washington1}
                    className="article-image"
                    alt={"Player Avatar"}
                    style={{ objectFit: "fill" }}
                  />
                  <p id="image-caption">
                    <i>Any Given Sunday</i> (1999)
                  </p>
                </div>
              </p>
              <h3>Round 1 - Julian Washington, RB</h3>
              <h4>Miami Sharks, #33</h4>
              <p>
                Got to take the sure thing in Julian Washington. This backfield
                merc may have an attitude problem, showboating issues, and some
                handling mistakes but he's one of few RB that's determined to
                hit the 1,500 yard mark and actually acheive it (and his bonus).
                Plus now that he's running routes up the middle again (thanks
                kid!) that should equate to even more points receiving catches
                from the backfield in this PPR league. Despite all the drama, a
                lot of what Julian brings to the table is talent. Maybe now that
                the Sharks have learned to work together, with their old OC
                turned new Head Coach Nick Crozier (and without Willie Beaman),
                we'll see them return to the Pantheon Cup on the back of
                Julian's <span className="green">Armani ass.</span>{" "}
              </p>
              <div className="image-and-caption">
                <img
                  src={Falco1}
                  className="article-image"
                  alt={"Player Avatar"}
                />
                <p id="image-caption">
                  <i>The Replacements</i> (2000)
                </p>
              </div>
              <h3>Round 2 - Shane Falco, QB</h3>
              <h4>Washington Sentinels, #16</h4>
              <p>
                Using tape from only four games on Shane Falco, picking this
                lefty Quarterback (one of three in Movieland along with Ronnie
                'Sunshine' Bass and Jack 'Cap' Rooney), might be a bit of a
                reach in the second round. After getting the sure thing in
                Washington (RB), and not wanting to be stuck with untrustworthly
                Paul Crewe, it was time to pull the trigger on this gunslinger
                and secure one of the only players worth his salt on the
                Sentinels (also, unsurprisingly, Beaman went early in the
                first). Athough the strike has ended, Shane remains a top
                prospect in our eyes.
              </p>
              <div className="image-and-caption">
                <img
                  src={Megget1}
                  className="article-image"
                  alt={"Player Avatar"}
                />
                <p id="image-caption">
                  <i>The Longest Yard</i> (2005)
                </p>
              </div>
              <h3>Round 3 - Earl Megget, RB</h3>
              <h4>Mean Machine, #23</h4>
              <p>
                In a league troubled with players of questionable moral
                character, Earl Megget stands-out as a{" "}
                <span className="green">stand-up</span> guy and not just for his
                pace of play. From his Quarterback to team Caretaker, everyone
                seems to be out to cheat the system. Being that Megget is one of
                the faster backs to ever play the game he's worth the third
                round pick-up. The weariness to play him definitely falls on
                what type of game the refs will call, watching points fall off
                the board due to bad officiating is an absolute{" "}
                <span className="green">killer.</span> We'll wait and see how
                this first week goes before throwing Earl in a starting spot.
              </p>
              <div className="image-and-caption">
                <img
                  src={Tidwell1}
                  className="article-image"
                  alt={"Player Avatar"}
                />
                <p id="image-caption">
                  <i>Jerry Maguire</i> (1996)
                </p>
              </div>
              <h3>Round 4 - Rod Tidwell, WR</h3>
              <h4>Arizona Cardinals, #85</h4>
              <p>
                An older potentially injury prone player is not the boost of
                confidence you want when getting what should be a solid
                10pts-a-week guy. Despite last year being his last year, it was
                a shock to see he decided to come back to play again and was
                absolutely the best on the board in the fourth. With his
                surprising turnaround (reports saying encouragement from his
                agent) Tidwell looks primed to be a top target in the Arizona
                offense. This veteran knows how to earn his money and is sure to
                produce some consistent points after catching the eye of the
                crowd and his QB. If TD celebrations are an inclination of
                athletic ability then,{" "}
                <span className="green">"In Rod We Trust".</span>{" "}
              </p>
              <div className="image-and-caption">
                <img
                  src={Sayers1}
                  className="article-image"
                  alt={"Player Avatar"}
                />
                <p id="image-caption">
                  <i>Brian's Song</i> (1971)
                </p>
              </div>
              <h3>Round 5 - Gale Sayers, RB</h3>
              <h4>Chicago Bears, #40</h4>
              <p>
                Having an NFL hall of famer RB sitting there in round five was a
                no-brainer. The first of two real NFL players to make the team,
                we nabbed Gale Sayers from Brian's Song. Absolutely cooler than
                cool even a decade <span className="green">before</span>{" "}
                Calrissian. Not only demonstrating great agility, route running,
                and football knowlege he's a team player who will go out of his
                way to motivate others. Anticipating that Sayers gets lots of
                touches on the ball and no touches on him from opposing
                defenses.
              </p>
              <div className="image-and-caption">
                <img
                  src={Tare1}
                  className="article-image"
                  alt={"Player Avatar"}
                />
                <p id="image-caption">
                  <i>The Comebacks</i> (2007){" "}
                </p>
              </div>
              <h3>Round 6 - Aseel Tare, RB</h3>
              <h4>Heartland State Comebacks, #34</h4>
              <p>
                After a totally unpredictable injury, Aseel Tare (pronounced:
                Ah-SEAL Tear-re) had a disappointingly short end to his first
                season under Coach Fields. Starting Aseel on day one may be a
                stretch to some but after seeing the Comebacks against the
                Undefeatables last season, and now with is favorite player back
                at 100%, it's seeming like the Lambeau Fields offense will
                return to it's run-heavy scheme. To quote his coaching staff,
                "Nothing can stop this kid! Nothing!"
              </p>
              <p>
                This sixth round grab was{" "}
                <span className="green">A-STEAL.</span>{" "}
              </p>
              <div className="image-and-caption">
                <img
                  src={Buddy1}
                  className="article-image"
                  alt={"Player Avatar"}
                />
                <p id="image-caption">
                  <i>Air-Bud: Golden Receiver</i> (1998){" "}
                </p>
              </div>
              <h3>Round 7 - Air-Bud, WR</h3>
              <h4>Fernfield Timberwolves, #K9</h4>
              <p>
                Air-Bud, "Buddy" as he is affectionately known by his teammates,
                is not only the fastest player in his league but in every league
                ever played. True, the scounting report says he still hasn't
                read the playbook, has disctraction problems, and poor route
                running but people said worse things about DeSean Jackson after
                his rookie season. Yes his weight and strength may be
                questionable, but there are zero issues when it comes to
                conditioning. Buddy won't see a sophmore slump because he isn't
                even a freshman yet. The YAC potential on this pup is insane,
                banking on a ton of yardage even with relatively low receptions.
                Not expecting 1,000 yards in a season lasting only 7 games on
                Thursdays after school, but definitely looking like 100+ per
                match-up.
              </p>
              <div className="image-and-caption">
                <img
                  src={Hanon3}
                  className="article-image"
                  alt={"Player Avatar"}
                />
                <p id="image-caption">
                  <i>The Little Giants</i> (1994){" "}
                </p>
              </div>
              <h3>Round 8 - Rashid "Hot Hands" Hanon, WR</h3>
              <h4>
                Little Giants,{" "}
                <span style={{ backgroundColor: "black" }}>#88</span>
              </h4>
              <p>
                This is pick may be a reach even for round eight. "Hot Hands"
                doesn't exactly have <span className="green">hot hands.</span>{" "}
                Although this Tiny Titan is reminiscent of a young Cliff
                Franklin (<i>The Replacements</i>) with a lot of up-side later
                in the season, as long as his method for catching footballs
                remains unnoticed (or becomes legal). Hanon's on a low potential
                rag-tag team that may not win every game, but with a little grit
                and a lot of determination, we'll be damned if we don't see some
                great garbage time points. Riding the pine as our back-up to the
                back-up, just in case this kid turns it around.
              </p>
              <div className="image-and-caption">
                <img
                  src={Gorman1}
                  className="article-image"
                  alt={"Player Avatar"}
                />
                <p id="image-caption">
                  <i>
                    The Garbage Picking Field Goal Kicking Philadelphia
                    Phenomenon
                  </i>{" "}
                  (1998){" "}
                </p>
              </div>
              <h3>Round 9 - Barney Gorman, K</h3>
              <h4>Philadelphia Eagles, #00</h4>
              <p>
                Speaking of garbage... At the ripe age of 48, and hot off a wild
                rookie season, Barney Gorman is a kicking machine. That new
                Eagles owner must be a nice guy-- but also a savvy one.
                Realizing he doesn't have to pay Gorman much, Jeff Lurie can
                take advantage of this dorky dad with the ball booting ability
                of an <span className="green">Australian rugby player.</span>{" "}
                After seeing Barney split the up-rights during kick-off, there's
                potential set-up for multiple 50+ yd FG attempts per game. And
                for what it's worth, this kicking garbage disposal also beat the
                biggest guy on the team (possibly the league) in a{" "}
                <span className="green">Rib-Off.</span>{" "}
              </p>
              <div className="image-and-caption">
                <img
                  src={Ampipe1}
                  className="article-image"
                  alt={"Player Avatar"}
                />
                <p id="image-caption">
                  <i>All the Right Moves</i> (1983){" "}
                </p>
              </div>
              <h3>Round 10 - Ampipe Bulldogs, DEF</h3>
              <h4>Defense/Special Teams</h4>
              <p>
                Featuring <span className="green">All-Armenian</span> Cornerback
                Stefan Djordjevic (Tom Cruise) and the highest recruited Ampipe
                player, Linebacker Brian Riley (Chris Penn) this solid goal-line
                defense rarely gives up points. Despite some questionable
                coaching calls and off-field drama, this is a tough team with
                high turn-over potential. The Ampipe Bulldogs may not win us
                Championships but they definitely won't lose them either.
              </p>
              <div className="image-and-caption">
                <img
                  src={Papale1}
                  className="article-image"
                  alt={"Player Avatar"}
                />
                <p id="image-caption">
                  <i>Invincible</i> (2006){" "}
                </p>
              </div>
              <h3>Round 11 - Vince Papale, WR</h3>
              <h4>Philadelphia Eagles, #83</h4>
              <p>
                Going with another Eagles old-timer who isn't a veteran, some
                may claim this <span className="green">hometown hero</span> is
                washed up but there was a lot of improvement from training camp
                to Papale's final game last season. And any reciever who can
                score TDs on special teams is quite impressive, maybe the
                Commish will let us use him for D/ST when the{" "}
                <span className="green">Bulldogs</span> have a BYE.
              </p>
              <div className="image-and-caption">
                <img
                  src={Cooper1}
                  className="article-image"
                  alt={"Player Avatar"}
                />
                <p id="image-caption">
                  <i>The Game Plan</i> (2007){" "}
                </p>
              </div>
              <h3>Round 12 - Kyle Cooper, TE</h3>
              <h4>Boston Rebels, #86</h4>
              <p>
                Kyle Cooper is not a sexy pick (no offense Hayes MacArthur).
                With tight ends being in high demand and short supply, Brian
                Murphy (<i>The Replacements</i>) and Cheeseburger Eddie (
                <i>The Longest Yard</i> [2005]) were both long gone by round 12.
                We aren't expecting much production here since the Rebels have a
                solid back field and plenty of options for their All-Star QB.
                Providing the key block in last year's game winning TD
                definitely showed that Cooper is{" "}
                <span className="green">the Rock</span> solid player every team
                needs. With that in mind there's a strong possibility that Kyle
                sees some passing plays come his way this season.
              </p>
              <p>
                That is, as long as he doesn't do something{" "}
                <span className="green">stupid</span> to cause an injury, like
                trip and fall during a pre-game walk in.
              </p>
            </div>
          </div>
          <Link to="/odevideostore">
            <div className="readMoreContainer">
              <p className="readMoreHeader">Read next:</p>
              <p className="readMoreContent">Ode to the Video Store</p>
              <p className="underscore"></p>
            </div>
          </Link>
          <ShareSquare
            description={"Check out this article from FilmSlobs! Hack is Wack"}
          />
          <div>
            <Link to="/email">
              <NotificationBell />
            </Link>
          </div>
        </div>
        <div className="relatedSidebar">
          <p className="seeAlso">You may also like ...</p>
          <Link to="/raresequelwins">
            <div className="image-and-caption">
              <img
                src={vader}
                className="article-image-sidebar"
                alt={"Player Avatar"}
              />
              <p id="sidebar-image-caption">Rare Sequel Wins</p>
            </div>
          </Link>
          <Link to="/bestzingers">
            <div className="image-and-caption">
              <img
                src={cinderella}
                className="article-image-sidebar"
                alt={"Player Avatar"}
              />
              <p id="sidebar-image-caption">
                Best Zingers from Minor Characters
              </p>
            </div>
          </Link>
          <Link to="/johnny">
            <div className="image-and-caption">
              <img
                src={Johnny}
                className="article-image-sidebar"
                alt={"Player Avatar"}
              />
              <p id="sidebar-image-caption">Johnny Utah is a Piece of Shit</p>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default FootballSlobs;
