import React from "react";
import { FaBell } from "react-icons/fa";

const NotificationBell = () => {
  return (
    <div style={styles.container}>
      <div style={styles.circle}>
        <FaBell size={20} style={styles.icon} />
      </div>
      <span style={styles.text}>Subscribe for updates</span>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    alignItems: "center",
    marginTop: "2rem",
  },
  circle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    backgroundColor: "black",
    marginRight: "10px",
  },
  icon: {
    color: "#baff39",
  },
  text: {
    fontSize: "16px",
  },
};

export default NotificationBell;
