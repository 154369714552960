import React, { useEffect, useState } from "react";

const Footer = () => {
  const [footerVisible, setFooterVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isFooterVisible =
        window.innerHeight + window.scrollY >= document.body.offsetHeight;
      setFooterVisible(isFooterVisible);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <footer
      className={footerVisible ? "footer-visible" : ""}
      id="footerWrapper"
    >
      <div>
        {" "}
        <div className="social-media-bottom">
          <a
            href="https://www.instagram.com/filmslobs/"
            className="icon-link"
            target="_blank"
            title="Instagram"
            rel="noopener noreferrer"
          >
            <i className="fab fa-instagram fa-lg"></i>
          </a>

          <a
            href="https://www.youtube.com/@film_slobs/featured"
            className="icon-link"
            target="_blank"
            title="Youtube"
            rel="noopener noreferrer"
          >
            <i className="fab fa-youtube-square fa-lg"></i>
          </a>

          <a
            className="icon-link"
            target="_blank"
            href="https://twitter.com/filmslobsoffish"
            title="Twitter"
            rel="noopener noreferrer"
          >
            <i className="fab fa-twitter-square fa-lg"></i>
          </a>
        </div>
        <p>&copy; FilmSlobs 2024</p>
      </div>
    </footer>
  );
};

export default Footer;
