import React from "react";
import Writing from "./Writing";

function Articles() {
  return (
    <div>
      <Writing />
    </div>
  );
}

export default Articles;
