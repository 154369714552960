import React, { useState } from "react";
import MovieDetails from "./MovieDetails";

function MovieCard(props) {
  let [click] = useState(false);

  let clickedOrNot = !click ? (
    <MovieDetails
      key={props.id}
      name={props.name}
      poster={props.poster}
      rating={props.rating}
      year={props.year}
      synopsis={props.synopsis}
      genre={props.genre}
      subgenre={props.subgenre}
      runtime={props.runtime}
      pairs={props.pairs}
      noPairs={props.noPairs}
      drinkRecipe={props.drinkRecipe}
      rated={props.rated}
      picture1={props.picture1}
      picture2={props.picture2}
      picture3={props.picture3}
      tagline={props.tagline}
      taglineWriter={props.taglineWriter}
      bulletOne={props.bulletOne}
      bulletTwo={props.bulletTwo}
      bulletThree={props.bulletThree}
      cast={props.cast}
    />
  ) : null;

  return (
    <div id={props.id} className="movieContainer">
      {clickedOrNot}
      <div className="movieTitleCard" title={props.name}>
        <div className="movieTitleText">
          {props.name} ({props.year})
        </div>
      </div>
    </div>
  );
}

export default MovieCard;
