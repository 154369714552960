import React from "react";
import MovieCard from "./MovieCard";
import movieList from "./MovieList";

let reviewedMovies = movieList.filter(
  (movie) =>
    movie.id === 98 ||
    movie.id === 867 ||
    movie.id === 6 ||
    movie.id === 46 ||
    movie.id === 120
);

let items = reviewedMovies.map((movie) => {
  return (
    <MovieCard
      key={movie.id}
      name={movie.name}
      poster={movie.poster}
      rating={movie.rating}
      year={movie.year}
      synopsis={movie.synopsis}
      genre={movie.genre}
      subgenre={movie.subgenre}
      runtime={movie.runtime}
      pairs={movie.pairs}
      noPairs={movie.noPairs}
      drinkRecipe={movie.drinkRecipe}
      rated={movie.rated}
      picture1={movie.picture1}
      picture2={movie.picture2}
      picture3={movie.picture3}
      tagline={movie.tagline}
      taglineWriter={movie.taglineWriter}
      bulletOne={movie.bulletOne}
      bulletTwo={movie.bulletTwo}
      bulletThree={movie.bulletThree}
      cast={movie.cast}
    />
  );
});

function EmployeePicks() {
  return (
    <div>
      <div className="employeePickContainer">
        <div className="genreSign employee">MOST RECENT REVIEWS</div>
        <div className="employeePickWeek">
          <div className="employeePickWeekText">
            Updated September 5th, 2023
          </div>
        </div>
      </div>

      <div className="renderContainer">{items}</div>
    </div>
  );
}

export default EmployeePicks;
