import React from "react";
import MovieList from "./MovieList";
import Modal from "@mui/material/Modal";

function GetRelatedMovies(props) {
  let relatedMovies = [];
  for (let i = 0; i < MovieList.length; i++) {
    if (MovieList[i].genre === props.genre) {
      relatedMovies.push(MovieList[i]);
    }
  }
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  let [boold, setBoold] = React.useState(false);
  let hideRelated = () => {
    setBoold(!boold);
  };

  let body = (
    <div className="modal">
      <div className="modal-content">
        <h2 className="tagline">"{props.tagline}"</h2>
        <span className="tagline-writer">- {props.taglineWriter}</span>

        <div className="topPicsContainer">
          <img src={props.picture1} alt="movie poster" className="picture1" />
          <img src={props.picture2} alt="movie poster" className="picture2" />
        </div>
        <p className="modal-synopsis">{props.synopsis}</p>
        <div className="bonusFeatures">
          <div className="pictureThreeContainer">
            <img
              src={props.picture3}
              alt="movie poster "
              className="picture3"
            />
          </div>
          <div className="letsGo">
            <div className="bonusFeatureBulletContainer">SPECIAL FEATURES</div>
            <div className="bonusFeatureBullets">
              <ul>
                <li>{props.bulletOne}</li>
                <li className="bottomBullet">{props.bulletTwo}</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="cast">{props.cast}</div>
        <div className="bottomContainer">
          <div className="iconOuterContainer">
            <div className="iconContainer">
              <i
                className="fas fa-cocktail fa-2x"
                style={{ color: "#c5c1c1" }}
              ></i>
            </div>
          </div>
          <div className="pairsWith">
            <div className="pairsWithTextDiv">
              This movie pairs well with {props.pairs}
            </div>
          </div>
        </div>

        <div className="modal-filmInfo">
          <div className="modal-mpaa-rating">{props.rated}</div>
          <div className="modal-runtime">
            <i className="far fa-clock"></i>
            {props.runtime}
          </div>
          <div className="modal-year">{props.year}</div>
          <div className="modal-rating">
            <span role="img" aria-label="beer">
              🍺
            </span>
            {props.rating}
            <span className="outOf">/6</span>
            <span style={{ display: "none" }}>
              {props.genre}
              {props.subgenre}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
  function getRandomRelated(relatedMovies) {
    let getRandomIndex = Math.floor(Math.random() * relatedMovies.length);
    let getRandomMovie = relatedMovies[getRandomIndex];
    relatedMovies.splice(getRandomIndex, 1);

    if (getRandomMovie.name === props.name) {
      return getRandomRelated(relatedMovies);
    }

    return getRandomMovie.poster;
  }

  return (
    <div className={boold ? "invisibleRelatedContainer" : "relatedContainer"}>
      <p className="relatedX" onClick={hideRelated}>
        X
      </p>
      <div className="relatedTitles"> You may also like ...</div>
      <div className="relatedFlex">
        <img src={getRandomRelated(relatedMovies)} alt={"Movie Poster"}></img>
        <img src={getRandomRelated(relatedMovies)} alt={"Movie Poster"}></img>
        <img src={getRandomRelated(relatedMovies)} alt={"Movie Poster"}></img>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
}

export default GetRelatedMovies;
