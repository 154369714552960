import React from "react";
import Gadbrooks from "../src/coldtake-pictures/DeanGadbrooks.jpg";
import { Link } from "react-router-dom";
import ArticlesBy from "../src/ArticlesBy";
import RareSequel from "./pictures/topfive/sequelwins.jpeg";
import Zingers from "./pictures/topfive/zingers.jpeg";
import BiggestGuns from "./pictures/topfive/taglines.jpeg";
import FarToPee from "./topfive-pictures/2far2P.png";
import Community from "../src/coldtake-pictures/community.jpeg";
import OldNotGood from "../src/coldtake-pictures/arabia.jpeg";
import DramaIsCheap from "../src/grabbag-pictures/drama.jpeg";
import OdeVideo from "../src/grabbag-pictures/odethumbnail-black.jpeg";
import HackIsWack from "../src/grabbag-pictures/hacking.png";
import Pacino from "../src/coldtake-pictures/pacino.jpeg";
import Morpheus from "../src/coldtake-pictures/Morph-Pimp-1.jpg";
import Substitute from "../src/grabbag-pictures/substitute.jpeg";
import Fade from "react-reveal/Fade";

function Dean() {
  return (
    <div>
      <div className="aboutWriterContainer">
        <div className="writerThumbnail">
          <img src={Gadbrooks} className="writerThumbnailImage"></img>
        </div>
        <div className="nameAndBlurbContainer">
          <div className="writerName">Dean Gadbrooks</div>
          <div className="writerBlurb">
            Dean is a{" "}
            <a
              style={{
                color: "#baff39",
                textDecoration: "underline",
                textDecorationStyle: "dashed",
              }}
              href="http://deangadbrooks.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              writer and standup
            </a>{" "}
            with a movie obsession.
          </div>
        </div>
      </div>
      <div className="alsoWrittenByContainer">
        <p className="seeAlsoHeader">Articles by dean</p>
        <Fade bottom>
          <Link to="/bestzingers">
            <ArticlesBy
              picture={Zingers}
              otherArticleTitle={"Best Zingers from Minor Characters"}
              otherArticleSynopsis={
                "The main character - the chosen one, the hero, the alpha. They're usually the one in control, the one everyone looks up to. It's rare to see them getting insulted, let alone by some obscure throwaway character. But sometimes the universe has a funny ..."
              }
            />
          </Link>
          <Link to="/biggestguns">
            <ArticlesBy
              picture={BiggestGuns}
              otherArticleTitle={"Biggest Guns on Movie Posters"}
              otherArticleSynopsis={
                "We love a huge blockbuster with an even bigger gun on the poster (henceforth known as a Glockbuster). When a gun gets top billing, it's basically a rubber stamped 'fuck yeah'. Whether the movie is genuinely good, orso-bad-it's-good, you know you're in for a treat."
              }
            />
          </Link>
          <Link to="/toofarpee">
            <ArticlesBy
              picture={FarToPee}
              otherArticleTitle={"Dudes Who Went Too Far to Pee"}
              otherArticleSynopsis={
                "In the weird world of cinema, the 'bathroom' scene is unsettlingly common. But we're not here to discuss ordinary lavatories or clichéd pant-soaking mishaps. We're shining a spotlight on a rare breed of urinary daredevil: dudes who go to the end of the Earth for ..."
              }
            />
          </Link>
          <Link to="/community">
            <ArticlesBy
              picture={Community}
              otherArticleTitle={"Lamest Community Episode"}
              otherArticleSynopsis={
                "Community is a comedy about eccentric students and the zany situations they get into at their local college. So when Troy, one of the the silliest and most over-the-top characters turns 21, we'll surely be treated to an episode of inebriated revelry and hilarious hijinks, right? WRONG."
              }
            />
          </Link>
          <Link to="/oldnotgood">
            <ArticlesBy
              picture={OldNotGood}
              otherArticleTitle={"Old ≠ Good"}
              otherArticleSynopsis={
                "We've all met this douche. The self-appointed curator of all things cinema, whose tastes are so refined and esoteric that they can't possibly enjoy anything that's not in black and white or made before they were born. They'll spend hours droning on about French ... "
              }
            />
          </Link>
          <Link to="/dramaischeap">
            <ArticlesBy
              picture={DramaIsCheap}
              otherArticleTitle={"Drama is Cheap"}
              otherArticleSynopsis={
                "We're sick and tired of drama scooping up all the awards and acclaim while comedy and action are treated like turds in a punch bowl. To be clear, we don't hate drama - drama is fine - but to act like it's the highest form of art is dead wrong. In this article we break down why ..."
              }
            />
          </Link>
          <Link to="/raresequelwins">
            <ArticlesBy
              picture={RareSequel}
              otherArticleTitle={"Rare Sequel Wins"}
              otherArticleSynopsis={
                "Sequels can be a lot like Jäger shots - they seem like a good idea at the time, but the more you have, the worse it gets. We've all seen sequels so bad they make the original look like Oscar material (hey there, Teen Wolf Too). But once in a blue moon they get it right - so right, ..."
              }
            />
          </Link>
          <Link to="/odevideostore">
            <ArticlesBy
              picture={OdeVideo}
              otherArticleTitle={"Ode to the Video Store"}
              otherArticleSynopsis={
                "This should surprise no one, but we loved the video store. From the tantalizing aroma of popcorn as you stepped through those magical doors, to the uncertainty of renting your desired DVD, to the bizarre interactions you'd have with other eccentric weirdos, the video ..."
              }
            />
          </Link>
          <Link to="/hackiswack">
            <ArticlesBy
              picture={HackIsWack}
              otherArticleTitle={"Hack is Wack"}
              otherArticleSynopsis={
                "Despite being an industry driven by technological advancement, Hollywood knows astonishingly little about portaying technology. From jacked hackers to keyboard wizards, this article runs down several mistakes often seen in pulse-pounding cyber slams."
              }
            />
          </Link>
          <Link to="/undeserving">
            <ArticlesBy
              picture={Pacino}
              otherArticleTitle={"Undeserving Winners"}
              otherArticleSynopsis={
                "Movie audiences are told to cheer for the 'good guy' no matter what. How fucking boring. At FilmSlobs we're all about backing the brainiacs and badasses, regardless of their moral compass. In real life, ethics obviously matter, but in Movieland? Give the villain a round of ..."
              }
            />
          </Link>
          <Link to="/morpheus">
            <ArticlesBy
              picture={Morpheus}
              otherArticleTitle={"Morpheus, the Pimp"}
              otherArticleSynopsis={
                "Neo had it all: a job at a top software company, a booming mini-disc side hustle, and a ticket to a long, happy life. But that all changed when he fell under the spell of a pill-pushing scam artist named Morpheus."
              }
            />
          </Link>
          <Link to="/teachingtip">
            <ArticlesBy
              picture={Substitute}
              otherArticleTitle={"Time-Tested Teaching Tip"}
              otherArticleSynopsis={
                "Times change, but the challenge of dealing with students remains a constant. The screen swiping, fidget spinning morons of today may seem more vexing than their predecessors, but rest assured, little troublemakers have been etching notes and launching ... "
              }
            />
          </Link>
        </Fade>
      </div>
    </div>
  );
}

export default Dean;
