import React, { useState } from "react";
import MovieList from "./MovieList";

function Randomizer() {
  let [clicked, setClick] = useState(false);
  let [click, addClick] = useState(0);
  let reviewedMovies = MovieList.filter((movie) => movie.synopsis !== "");
  let randomMovie =
    reviewedMovies[Math.floor(Math.random() * reviewedMovies.length)];

  let showMovie = () => {
    setClick(randomMovie);
    addClick((click = click + 1));
    console.log("click is equal to " + click);
    console.log(pairs);
  };

  let movieName = randomMovie.name;
  let moviePoster = randomMovie.poster;
  let movieRating = randomMovie.rating;
  let movieYear = randomMovie.year;
  let movieSynopsis = randomMovie.synopsis;
  let runtime = randomMovie.runtime;
  let MPAA = randomMovie.rated;
  let pairs = randomMovie.pairs ? randomMovie.pairs.slice(0, -1) : "";
  let noPairs = randomMovie.noPairs;
  let ingredients = randomMovie.drinkRecipe;
  let bulletOne = randomMovie.bulletOne;
  let bulletTwo = randomMovie.bulletTwo;
  let movieTitle = `https://www.google.com/search?q=where+to+watch+${movieName}`;

  let showPairs =
    pairs && ingredients ? (
      <span className="pairsWithRandomText">
        <strong>Pairs well with {pairs}</strong>: {ingredients}
      </span>
    ) : noPairs ? (
      <span className="pairsWithRandomText">
        <strong>Pairs well with {noPairs}</strong>
      </span>
    ) : (
      ""
    );

  let showRandomLimit =
    click >= 4
      ? `No more random selections. The movie gods have spoken, and you will watch
      ${movieName}.`
      : "";

  let hideButton =
    click < 4 ? (
      <div className="randomButtonDiv">
        <button className="randomButton" onClick={showMovie}>
          Give me a movie!
        </button>
      </div>
    ) : null;

  let renderedMovie = (
    <div className="randomContainer">
      <div className="imageContainer">
        <div className="img-reviewed-random">
          <img
            className="img-img-reviewed-random"
            src={moviePoster}
            alt="movie cover"
          ></img>
          <a target="_blank" rel="noopener noreferrer" href={movieTitle}>
            <div className="img-overlay-reviewed-random">
              <div className="img-title-reviewed-random">Watch Me!</div>
              <p className="img-description-reviewed-random">
                Click to find where
              </p>
            </div>
          </a>
        </div>
      </div>
      <div className="scrollableContainer">
        <div className="infoContainer">
          <p className="movieName">{movieName}</p>
          <div className="ratingsContainer">
            <p id="MPAA">{MPAA}</p>
            <p>
              <i className="far fa-clock"></i>
              {runtime}
            </p>
            <p>{movieYear}</p>
            <span
              role="img"
              aria-label="Emoji"
              style={{ verticalAlign: "middle" }}
            >
              🍺{movieRating}
            </span>{" "}
          </div>

          <div className="synopsis">
            <p>
              {movieSynopsis === ""
                ? "Hey there fellow slobs! We'll have a review and drink pairing for ya shortly!"
                : movieSynopsis}
            </p>
          </div>
          <p className="randomSpecialFeatures">
            <strong>
              <u>Special Features</u>
            </strong>
          </p>
          <div className="randomBullets">
            <p>&#x2022;{bulletOne}</p>
            <p>&#x2022;{bulletTwo}</p>
          </div>

          <div className="pairsWithRandom">
            <p>{showPairs}</p>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="movieBackgroundRandom">
      <p className="randomizerText">
        Tired of <span className="green">endlessly scrolling?</span>
      </p>
      <p className="randomizerSubtext">
        Click the <span className="green">randomizer</span> and commit to
        whatever movie comes up
      </p>

      <div>{hideButton}</div>
      <div className="randomizerLimit">{showRandomLimit}</div>
      <div className="randomMovieContainer">
        <div className="randomCard">
          <div>{renderedMovie}</div>
        </div>
      </div>
    </div>
  );
}

export default Randomizer;
