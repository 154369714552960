import React from "react";
import { Link } from "react-router-dom";
import ArticlesBy from "./ArticlesBy";
import DisappointingBadass from "./pictures/topfive/badvillains.jpeg";
import FakeMovies from "./topfive-pictures/koogler.jpeg";
import UtahPOS from "../src/coldtake-pictures/johnnyutah.jpeg";
import GodfatherStinks from "../src/coldtake-pictures/godfatherstinks.jpeg";
import Football from "../src/grabbag-pictures/FootballBG.jpg";
import Boozecruize from "../src/grabbag-pictures/BC_TN2.jpg";
import SpencePic from "../src/pictures/Spence.webp";

import Fade from "react-reveal/Fade";

function Spence() {
  return (
    <div>
      <div className="aboutWriterContainer">
        <div className="writerThumbnail">
          <img src={SpencePic} className="writerThumbnailImage"></img>
        </div>
        <div className="nameAndBlurbContainer">
          <div className="writerName">Spence Diamond</div>
          <div className="writerBlurb">The little Tucker from Tokyo.</div>
        </div>
      </div>
      <div className="alsoWrittenByContainer">
        <p className="seeAlsoHeader">Articles by Spence</p>
        <Fade bottom>
          <Link to="/mostdisappointing">
            <ArticlesBy
              picture={DisappointingBadass}
              otherArticleTitle={"Most Disappointing Badasses"}
              otherArticleSynopsis={
                "Oftentimes in film and TV we see stories of unlikely characters achieve incredible feats. They become who they were meant to be and save the day. We’re told not to judge a book by its cover."
              }
            />
          </Link>
          <Link to="/fakemovies">
            <ArticlesBy
              picture={FakeMovies}
              otherArticleTitle={"Fake Movies We Actually Want to See"}
              otherArticleSynopsis={
                "Oftentimes in film and TV we see stories of unlikely characters achieve incredible feats. They become who they were meant to be and save the day. We’re told not to judge a book by its cover."
              }
            />
          </Link>
          <Link to="/johnny">
            <ArticlesBy
              picture={UtahPOS}
              otherArticleTitle={"Johnny Utah is a Piece of Shit"}
              otherArticleSynopsis={
                "𝘗𝘰𝘪𝘯𝘵 𝘉𝘳𝘦𝘢𝘬 is a great movie and after more than 30 years since hitting theaters the action sequences, jokes, and girls (technically, Babes) hold up."
              }
            />
          </Link>
          <Link to="/boozecruize">
            <ArticlesBy
              picture={Boozecruize}
              otherArticleTitle={"Ride the Booze Cruize"}
              otherArticleSynopsis={
                "Tom Cruise is the most well known movie star today. That’s not a surprise, considering the incredible list of blockbuster hits he’s been pumping out non-stop since 1983 While maybe most known as Maverick, Jack Reacher, or Ethan Hunt ... "
              }
            />
          </Link>
          <Link to="/footballslobs">
            <ArticlesBy
              picture={Football}
              otherArticleTitle={"FilmSlobs Fantasy Breakdown"}
              otherArticleSynopsis={
                "The best time of year is upon us. Kids have gone back to school, hot days are reluctantly giving way to ever growing cooler nights, and armchair quarterbacks have fresh bad takes for all 12 of their Twitter followers: it's (fantasy) football season! "
              }
            />
          </Link>
        </Fade>
      </div>
    </div>
  );
}

export default Spence;
