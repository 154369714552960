import React from "react";

function TermsConditions() {
  return (
    <div>
      <div className="termsContainer">
        <p>
          <em>Updates</em>
        </p>
        <br></br>
        <p className="termsContent">
          The following TERMS OF USE is subject to change at any time and at the
          sole discretion of the site owner and operator. Please visit the site
          regularly for updates.
        </p>
        <p className="termsHeader">TERMS AND CONDITIONS</p>
        <p className="termsContent">
          <strong>Use of the Website</strong>—By accessing the website, you
          warrant and represent to the website owner that you are legally
          entitled to do so and to make use of information made available via
          the website.
        </p>
        <br></br>
        <p className="termsContent">
          <strong>Trademarks</strong>—The trademarks, names, logos and service
          marks (collectively “trademarks”) displayed on this website are
          registered and unregistered trademarks of the website owner. Nothing
          contained on this website should be construed as granting any license
          or right to use any trademark without the prior written permission of
          the website owner.
        </p>
        <br></br>
        <p className="termsContent">
          <strong>External links</strong>—External links may be provided for
          your convenience, but they are beyond the control of the website owner
          and no representation is made as to their content. Use or reliance on
          any external links and the content thereon provided is at your own
          risk.
        </p>
        <br></br>
        <p className="termsContent">
          <strong>Warranties</strong>—The website owner makes no warranties,
          representations, statements or guarantees (whether express, implied in
          law or residual) regarding the website.
        </p>
        <br></br>
        <p className="termsContent">
          <strong>Disclaimer of liability</strong>—The website owner shall not
          be responsible for and disclaims all liability for any loss,
          liability, damage (whether direct, indirect or consequential),
          personal injury or expense of any nature whatsoever which may be
          suffered by you or any third party (including your company), as a
          result of or which may be attributable, directly or indirectly, to
          your access and use of the website, any information contained on the
          website, your or your company’s personal information or material and
          information transmitted over our system. In particular, neither the
          website owner nor any third party or data or content provider shall be
          liable in any way to you or to any other person, firm or corporation
          whatsoever for any loss, liability, damage (whether direct or
          consequential), personal injury or expense of any nature whatsoever
          arising from any delays, inaccuracies, errors in, or omission of any
          share price information or the transmission thereof, or for any
          actions taken in reliance thereon or occasioned thereby or by reason
          of non-performance or interruption, or termination thereof.
        </p>
        <br></br>
        <p className="termsContent">
          <strong>Conflict of terms</strong>—If there is a conflict or
          contradiction between the provisions of these website terms and
          conditions and any other relevant terms and conditions, policies or
          notices, the other relevant terms and conditions, policies or notices
          which relate specifically to a particular section or module of the
          website shall prevail in respect of your use of the relevant section
          or module of the website.
        </p>
        <br></br>
        <p className="termsContent">
          <strong>Severability</strong>—Any provision of any relevant terms and
          conditions, policies and notices, which is or becomes unenforceable in
          any jurisdiction, whether due to being void, invalidity, illegality,
          unlawfulness or for any reason whatever, shall, in such jurisdiction
          only and only to the extent that it is so unenforceable, be treated as
          void and the remaining provisions of any relevant terms and
          conditions, policies and notices shall remain in full force and
          effect.
        </p>
        <br></br>
        <p className="termsContent">
          <strong>Applicable laws (choice of venue and forum)</strong>—Use of
          this website shall in all respects be governed by the laws of the
          state of California, U.S., regardless of the laws that might be
          applicable under principles of conflicts of law. The parties agree
          that the California State courts located in San Diego county,
          California, shall have exclusive jurisdiction over all controversies
          arising under this agreement and agree that venue is proper in those
          courts.
        </p>
        <br></br>
        <br></br>
        <br></br>
        <p>TERMS OF USE</p>
        <br></br>
        <p className="termsContent">
          Please read the following terms of use relating to your use of this
          site, and all the sites listed below under our brand, carefully. By
          using these sites, you agree to these terms of use. We reserve the
          right to modify these terms at any time, so please check this page
          periodically for changes. By using these sites after we post any
          changes to these terms, you agree to accept those changes, whether or
          not you have reviewed them or been notified about them. If at any time
          you choose not to accept these terms of use, please do not use this
          site or any of our sites.
        </p>
        <br></br>
        <p>
          <strong>PRIVACY POLICY</strong>
        </p>

        <p className="termsContent">
          We are committed to protecting your privacy and security and have
          explained in detail the steps we take to do so. We control this site
          from offices in the United States of America. We do not represent that
          materials on the site are appropriate or available for use in other
          locations. Persons who choose to access this site from other locations
          do so on their own initiative, and are responsible for compliance with
          local laws, if and to the extent local laws are applicable.
        </p>
        <br></br>
        <p>
          <strong>
            OUTSIDE SITES (THEIR PRIVACY POLICIES MAY BE DIFFERENT).
          </strong>
        </p>
        <p className="termsContent">
          The Site contains links to other websites, like our sponsor’s
          websites, that may offer products or services that our customers might
          find useful. These sites may request information from you. In such
          instances, the collection and use of your personal information will be
          governed by the privacy policy applicable to that site. We do not
          control the privacy policies, contents or links that appear on these
          sites. We encourage you to review the privacy policies of any third
          party sites or services before providing any of them with your
          personal information.
        </p>
        <br></br>
        <p>
          <strong>HOW AND WHY WE COLLECT INFORMATION:</strong>
        </p>
        <p className="termsContent">
          We collect your information in order to record and support your
          participation in the activities you select. If you register for a
          consulting seminar, for example, the information is used to reserve
          your seat, to track your preferences, and to keep you informed about
          the seminar and related events. As a visitor to our Sites, you can
          engage in many activities without providing any personal information.
          In connection with other activities, such as utilizing registering for
          a seminar or participating in a sweepstakes, we may ask you to provide
          certain information about yourself by filling out and submitting an
          online form. It is completely optional for you to engage in these
          activities. If you elect to engage in these activities, however, we
          may ask that you provide us personal information, such as your first
          and last name, mailing address (including ZIP code), e-mail address, ,
          telephone, credit card, and other personal information. If you do not
          provide the mandatory data with respect to a particular activity, you
          will not be able to engage in that activity. In all cases, we will
          collect personal identification information from you only if you
          voluntarily submit such information to us. We will also tell you how
          we use your information or if we share it with another party. Except
          as otherwise provided in this policy, we will never intentionally
          disclose any personal identification information about you as an
          individual user to any third party without having received your
          permission. If you supply us with your contact information you may
          receive periodic e-mails, mailings or calls from us with information
          on new products and services, important issues, or upcoming events. If
          you wish to be removed from any postal, email, phone, or other lists,
          please let us know by emailing us. You can also write us or call us at
          the contact information above. Please provide us with your exact name,
          email address, mailing address, and phone. We will be sure your name
          is removed from the appropriate lists immediately. When you use our
          Sites, we or our authorized technology services provider may also
          collect certain technical and routing information from you to
          facilitate your use of the Site and its services. We use this
          information to administer the Site and to understand and measure
          traffic patterns on the Site so that we know which areas of our sites
          are favorites of our users, which areas need improvement, and what
          technologies are being used so that we may continually improve our
          sites. This information is collected in aggregate form, without
          identifying you or any user individually. We may use this aggregate,
          non-identifying statistical data for statistical analysis, marketing,
          or similar promotional purposes. This data is often tracked by us our
          our technology services provider by using "cookies" during your visit.
          A cookie is small amount of data that is transferred to your browser
          by a web server and can only be read by the server that gave it to
          you. It functions as your identification card and enables us to record
          your passwords, purchases, and preferences. It cannot be executed as
          code or deliver viruses. Most browsers are initially set to accept
          cookies. You can set your browser to notify you when you receive a
          cookie, giving you the chance to decide whether or not to accept it.
          (For some web pages that require an authorization, cookies are not
          optional. Users choosing not to accept cookies will probably not be
          able to access those pages.
        </p>
        <br></br>
        <p>
          <strong>EXCEPTIONS TO PRIVACY POLICY:</strong>
        </p>
        <p className="termsContent">
          While we are committed to keeping your information secure and private,
          we have the following exceptions to our privacy policy: We will
          release specific information about you or your account to comply with
          any valid legal inquiry or process such as a search warrant, subpoena,
          statute or court order. We will also release specific information in
          special cases, such as if there is an attempted breach of the security
          of the Sites, or a physical or property threat to you or others. We
          may also transfer user information, including personally identifiable
          information, in connection with a corporate merger, consolidation, the
          sale of related assets or corporate division or other fundamental
          corporate change. Further, the information you enter when making a
          purchase or an online donation will be shared with payment processors,
          financial gateways, and your credit card company to authorize credit
          card payments. Such information may also be shared with necessary
          third parties solely for the purpose or carrying out the transactions.
          Please note that if you give out personal information online through a
          discussion board or posting site, that information can be collected
          and used by third parties. Although we strive to protect our users'
          privacy, we cannot guarantee the security of information you post in
          these forums. You disclose such information at your own risk.
        </p>
        <br></br>
        <p>
          <strong>MISCELLANEOUS:</strong>
        </p>
        <p className="termsContent">
          These terms will be governed by and construed in accordance with the
          laws of the State of California, without regard to any principles of
          conflicts of law. You agree that any action at law or in equity that
          arises out of or relates to these terms will be subject to mediation
          in the State of California. If any of these terms of use are found
          unlawful, void, or for any reason unenforceable, then that provision
          will be considered severable from the remaining terms of use, and will
          not affect the validity and enforceability of the remaining
          provisions. These terms may be modified only by our posting of changes
          to these terms of use on our Sites.
        </p>
        <br></br>
        <p>
          <center>This website is parody. Please drink responsibly.</center>
        </p>
        <br></br>
      </div>
    </div>
  );
}

export default TermsConditions;
