import React from "react"
import EmptyDVD from "../src/pictures/FSLogo/BlankDVD.png"

const NoResults = () => (
  <div>
    <div className="emptyDVD">
      <img src={EmptyDVD} alt={"DVD BOX"} className="dvdPicture" />
    </div>
  </div>
)

export default NoResults
