import React from "react";

function FAQ() {
  return (
    <div>
      <div>
        <section className="FAQ">
          <div className="container">
            <div className="faqHeader">
              Frequently Asked <span className="green">Questions</span>
            </div>
            <div className="accordion">
              <div className="accordion-item" id="question1">
                <a className="accordion-link" href="#question1">
                  Who are you guys?<i className="plus">+</i>
                  <i className="minus">-</i>
                </a>
                <div className="answer">
                  <p>A couple of average Joes who love movies.</p>
                </div>
              </div>
              <div className="accordion-item" id="question2">
                <a className="accordion-link" href="#question2">
                  Why should I trust your judgment?<i className="plus">+</i>
                  <i className="minus">-</i>
                </a>
                <div className="answer">
                  <p>
                    Think of us as the oddball video store clerk. You may not
                    agree with 100% of our reviews, but damn if we can't give a
                    solid recommendation most of the time.{" "}
                  </p>
                </div>
              </div>

              <div className="accordion-item" id="question3">
                <a className="accordion-link" href="#question3">
                  How do you decide what to review?
                  <i className="plus">+</i>
                  <i className="minus">-</i>
                </a>
                <div className="answer">
                  <p>
                    There isn’t really a rhyme or reason. It’s kinda random.
                    Could be a movie we just watched, remembered, or really
                    like.
                  </p>
                </div>
              </div>

              <div className="accordion-item" id="question4">
                <a className="accordion-link" href="#question4">
                  Why don't you have the movie I'm looking for?
                  <i className="plus">+</i>
                  <i className="minus">-</i>
                </a>
                <div className="answer">
                  <p>
                    It's possible the movie is too obscure even for us, but more
                    likely it's that our team is small. We're adding new titles
                    every week, though - so drop us an email if you have
                    requests.
                  </p>
                </div>
              </div>

              <div className="accordion-item" id="question5">
                <a className="accordion-link" href="#question5">
                  Are you going to add TV shows?
                  <i className="plus">+</i>
                  <i className="minus">-</i>
                </a>
                <div className="answer">
                  <p>
                    Absolutely. We love TV too, and shows are (or were)
                    technically on film so they count!
                  </p>
                </div>
              </div>
              <div className="accordion-item" id="question6">
                <a className="accordion-link" href="#question6">
                  Why can't I leave a rating?
                  <i className="plus">+</i>
                  <i className="minus">-</i>
                </a>
                <div className="answer">
                  <p>
                    Unlike other sites, we don't allow movies to go down in
                    flames because a horde of trolls rallies to torpedo the
                    rating.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
      </div>
    </div>
  );
}

export default FAQ;
