import React from "react";
import SpacePee from "../topfive-pictures/space-pee.jpeg";
import Community from "../coldtake-pictures/community.jpeg";
import HanksPee from "../topfive-pictures/hankspee.jpeg";
import Stormare1 from "../topfive-pictures/stormare-1.jpg";
import Stormare2 from "../topfive-pictures/stormare-2.jpg";
import Stormare3 from "../topfive-pictures/stormare-3.jpg";
import Stormare4 from "../topfive-pictures/stormare-4.jpg";
import Stormare5 from "../topfive-pictures/stormare5.jpg";
import Stormare6 from "../topfive-pictures/stormare6.jpg";
import Wilson from "../topfive-pictures/wilson.jpeg";
import ShareCircle from "../ShareCircle.js";
import dramaischeap from "../../src/grabbag-pictures/drama.jpeg";
import ShareSquare from "../ShareSquare";
import { Link } from "react-router-dom";
import Urinator from "../topfive-pictures/the-urinator.jpeg";
import urbanlegend2 from "../topfive-pictures/urbanlegend2.jpg";
import urbanlegend from "../topfive-pictures/urban-legend.png";
import kumarcar from "../topfive-pictures/kumar.png";
import kumarpubes from "../topfive-pictures/kumarpubes.png";
import kumar3 from "../topfive-pictures/kumar3.jpeg";
import promises1 from "../topfive-pictures/promises1.jpg";
import promises2 from "../topfive-pictures/promises2.png";
import promises3 from "../topfive-pictures/promises3.jpg";
import hackiswack from "../../src/grabbag-pictures/hacking.png";
import NotificationBell from "../NotificationBell";

function TooFarPee() {
  return (
    <div>
      <div className="currentRoute">
        <Link to="/articles">
          <span className="underline">Articles</span>
        </Link>{" "}
        &gt;{" "}
        <Link to="writingtopFive">
          <span className="underline">Top 5 Lists</span>
        </Link>{" "}
        &gt; Dudes Who Went Too Far to Pee
      </div>
      <div className="articleContentContainer">
        <div class="article-container">
          <div class="article-container-header">
            <p>Dudes Who Went Too Far to Pee</p>
          </div>
          <div className="published">PUBLISHED Jul 24, 2023</div>
          <div className="authorName">
            BY{" "}
            <p>
              <Link to="/dean">
                <span className="green" id="author-name">
                  Dean
                </span>
              </Link>
            </p>
            <ShareCircle
              description={
                "Check out this article from FilmSlobs! Top 5 Dudes Who Went Too Far to Pee"
              }
            />
          </div>
          <div class="article-container-body">
            <div className="image-and-caption">
              <img
                src={Stormare3}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">Where's Waldo?</p>
            </div>
            <p>
              In the weird world of cinema, the "bathroom" scene is unsettlingly
              common - but we're not here to discuss ordinary lavatories or
              clichéd pant-soaking mishaps. We're shining a spotlight on a rare
              breed of urinator:{" "}
              <span className="green">
                dudes who go to the ends of the Earth for a squirt.
              </span>
            </p>
            <p>
              It's a puzzling quirk that rejects the ultimate male superpower:
              unrestrained peeing on demand - regardless of location. Maybe
              these characters are shy. Maybe they require absolute silence
              before relinquishing a single drop. The only certainty is when you
              spurn such a gift from the gods, you quickly taste their
              vengeance. As you'll see,{" "}
              <span className="green">
                going to far to pee never turns out well.
              </span>
            </p>
            <p>
              So without further ado,{" "}
              <span className="green">let's unzip this article.</span>
            </p>

            <h2>5.) Tom Hanks - Cast Away</h2>
            <p>
              {" "}
              Yes, yes, we know. Tom Hanks peeing in movies has reached the
              status of a well-known trope; a golden stream of cinematic
              consistency rivaled only by Tom Cruise's running or Brad Pitt's
              eating.{" "}
            </p>
            <div className="image-and-caption">
              <img
                src={HanksPee}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">
                Behold snapshots from his finest peeformances
              </p>
            </div>
            <p>
              But all eliminations are not created equal, and Hanks takes
              distance to the next level in Cast Away. You may be thinking
              "Wait! He peed in Apollo 13 -- surely outer space is his
              furthest!". While it's true he boldly goes where no bladder has
              gone before, his <span className="green">purpose</span> in going
              to space <span className="green">wasn't to pee.</span>{" "}
            </p>

            <div className="image-and-caption">
              <img
                src={SpacePee}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">Though pee he did.</p>
            </div>
            <p>
              In Castaway, he went to even greater lengths to have a private
              moment on his uninhabited island. Let us repeat that. His{" "}
              <span className="green">UN. INHABITED. ISLAND</span>. If you're
              the only living soul in a Pacific archipelago, peeing on your own
              toes is going too far to pee. Do you really need extra privacy
              when there's NO ONE around to see?
            </p>
            <div className="image-and-caption">
              <img
                src={Wilson}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">How very dare you</p>
            </div>
            <p>
              Yet one night he embarks on an epic journey through forests,
              beaches, and the vast ocean just to dribble a few drops of
              personality on the scene. It didn't add anything to the plot - it
              was probably scripted just so Hanks could proudly maintain his
              unbroken record of pissing in every movie ever. When it comes to
              unwavering determination to execute a goal, Tom is more relentless
              than the T-800 hunting Sarah Connor.
            </p>
            <div className="image-and-caption">
              <div className="article-image-vertical-div">
                <img
                  src={Urinator}
                  className="article-image-vertical"
                  alt={"Player Avatar"}
                />
                <p id="image-caption">FLUSHING INTO THEATERS THIS SUMMER!</p>
              </div>
            </div>
            <h2>4.) Joshua Jackson - Urban Legend</h2>
            <div className="image-and-caption">
              <img
                src={urbanlegend2}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">"Don't peek when I take a leak"</p>
            </div>
            <p>
              When a bunch of your friends are getting murdered, it's always a
              good idea to{" "}
              <span className="green">split apart and head for the woods</span>.
              And with your life potentially about to end, there's no better way
              to spend your final moments than trying to get laid. But
              absolutely under no circumstances can you let the grim reaper see
              your dick.
            </p>
            <div className="image-and-caption">
              <img
                src={urbanlegend}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">"Avert your eyes!"</p>
            </div>
            <p>
              {" "}
              When Jackson's sexual advances are rejected, he gets out of the
              car and roams through the woods{" "}
              <span className="green">forever</span> to find a suitable spot to
              whiz. The sudden transformation from Mr. Bold into Dr. Shy was
              peculiar, as he very nearly whipped out his wang to initiate a
              hookup mere seconds before. Alas, once rebuffed he sets out on an
              expedition which makes Lewis and Clarke look like amateurs on a
              nature stroll.
            </p>
            <p>
              After channeling his stream into Dawson's Creek, Jackson is last
              seen on the roof of his car with a noose around his neck. You may
              be thinking, "Oh of course - a little autoerotic asphyxiation to
              fix his blueballs", but no. A killer heard him clomping around the
              woods from miles away, then strung him up. It goes to show that if
              you venture too far, your life - not bladder - could get drained.
            </p>
            <h2>3.) Josef Altin - Eastern Promises</h2>
            <div className="image-and-caption">
              <img
                src={promises1}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">
                Fosters? In addition to taking a piss he's drinking it.
              </p>
            </div>
            <p>
              Next up to the bowl is this little turd who loudly cheers for
              Arsenal while surrounded by Chelsea fans. That alone could have
              gotten him killed, but what really sealed his fate was walking
              deep into a cemetery for a squirt. He could've gone behind a tree
              on the path, but privacy is always recommended when desecrating a
              burial ground.
            </p>
            <div className="image-and-caption">
              <img
                src={promises2}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">
                "Urine trouble now! The consequences shall be grave"
              </p>
            </div>
            <p>
              Right when he thinks he has a moment alone to 'pay his respects',
              two men emerge and slit his throat.
            </p>
            <div className="image-and-caption">
              <img
                src={promises3}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">
                On the bright side, he did drain the main vein
              </p>
            </div>
            <p>
              {" "}
              It turns out to be a revenge killing, but we like to believe it's
              punishment from the movie gods for going too far to pee.
            </p>

            <h2>2.) Kal Penn - Harold and Kumar Go to White Castle</h2>
            <div className="image-and-caption">
              <img
                src={kumarcar}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">
                "Be right back! Just heading to the next state"
              </p>
            </div>
            <p>
              As longtime friends and roommates, Harold and Kumar share a bond
              where personal space takes a backseat. But when a roadside stop
              becomes the stage, Kumar embarks on a pilgrimage to find his
              much-needed privacy.
            </p>
            <div className="image-and-caption">
              <img
                src={kumar3}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">Or so he thought</p>
            </div>
            <p>
              Suddenly a random dude emerges and pisses all over the unwritten
              rules of urinal etiquette. First he breaches the code by choosing
              the spot directly next to Kumar. And if{" "}
              <span className="green">defying the sacred buffer zone</span>{" "}
              wasn't enough, he breaks yet another taboo by engaging in
              conversation mid-stream. This entire situation could have been
              avoided if he'd simply peed behind the car.
            </p>

            <p>
              Although Kumar wasn't murdered, he did have to endure life's
              second-worst fate: having a stranger compliment you on the majesty
              of your bush.
            </p>

            <div className="image-and-caption">
              <img
                src={kumarpubes}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">"Nice pubes"</p>
            </div>

            <h2>1.) Peter Stormare - The Lost World: Jurassic Park </h2>
            <div className="image-and-caption">
              <img
                src={Stormare1}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">This lizard will not be drained</p>
            </div>
            <p>We've made it to that last final shake. What a relief.</p>
            <p>
              After watching thousands of movies, scenes either blend together
              or disappear from memory entirely.{" "}
              <span className="green">This scene</span>, however, has been
              floating in the upper deck of our consciousness and{" "}
              <span className="green">refused to flush since 1997.</span> We
              still can't wrap our heads around it. Why the FUCK did he go so
              far to pee? He's on an island populated exclusively with ravenous
              man-eating monsters. Is someone seeing your schlong really worse
              than death by a thousand bites?
            </p>
            <div className="image-and-caption">
              <img
                src={Stormare5}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">Yes</p>
            </div>
            <p></p>
            <p></p>

            <p>
              Dieter <span className="green">could not</span> have gone further.
              Safely within the confines of camp, he should've turned his back
              to his men and let 'er rip. Some people say his death is his buddy
              Carter's fault - he should've known Dieter wasn't with the group
              when they broke camp. But in Carter's defense, how could he
              possibly know that Dieter was about to trek the length of Isla
              Sorna, swim to Isla Nubar, then catch a flight to Pluto to take a
              piss?
            </p>
            <p>
              Dieter stops at <span className="green">nothing</span> to find a
              private spot. After about seven miles into his journey he falls
              down a cliff and{" "}
              <span className="green">keeps going further</span>. Even the
              dinosaurs are stunned. Dude gets so deep in the jungle that the
              Compy's stop chasing just to heckle him.
            </p>
            <div className="image-and-caption">
              <img
                src={Stormare2}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">
                "Hey, Dieter! Dieter! We can see your wiener!"
              </p>
            </div>
            <p>
              Instead of running back in the direction he came, he continues
              plowing heedlessly ahead into the deepest interior of the island,
              where he ultimately meets his end.{" "}
            </p>
            <div className="image-and-caption">
              <img
                src={Stormare6}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">At least one liquid was released</p>
            </div>
            <p>
              As Jerry Seinfeld knows, holding it in for too long can cause
              Uromysitisis - so don't stray too far unless you're OK with
              risking death.
            </p>

            <div className="image-and-caption">
              <img
                src={Stormare4}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">Or an awful makeout session</p>
            </div>
          </div>{" "}
          <Link to="/mostdisappointing">
            <div className="readMoreContainer">
              <p className="readMoreHeader">Read next:</p>
              <p className="readMoreContent">Most Disappointing Badasses</p>
              <p className="underscore"></p>
            </div>
          </Link>
          <ShareSquare
            description={
              "Check out this article from FilmSlobs! Top 5 Dudes Who Went Too Far to Pee"
            }
          />
          <div>
            <Link to="/email">
              <NotificationBell />
            </Link>
          </div>
        </div>
        <div className="relatedSidebar">
          <p className="seeAlso">You may also like ...</p>
          <Link to="/hackiswack">
            <div className="image-and-caption">
              <img
                src={hackiswack}
                className="article-image-sidebar"
                alt={"Player Avatar"}
              />
              <p id="sidebar-image-caption">Hack is wack</p>
            </div>
          </Link>
          <Link to="/dramaischeap">
            <div className="image-and-caption">
              <img
                src={dramaischeap}
                className="article-image-sidebar"
                alt={"Player Avatar"}
              />
              <p id="sidebar-image-caption">Drama is cheap</p>
            </div>
          </Link>
          <Link to="/community">
            <div className="image-and-caption">
              <img
                src={Community}
                className="article-image-sidebar"
                alt={"Player Avatar"}
              />
              <p id="sidebar-image-caption">Lamest community episode</p>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default TooFarPee;
