import React from "react";
import Modal from "@mui/material/Modal";
import GetRelatedMovies from "./GetRelatedMovies";
import RecipeCard from "./RecipeCard";

export default function SimpleModal(props) {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  let movieTitle = `https://www.google.com/search?q=where+to+watch+${props.name}`;

  let [clicked, setClick] = React.useState(false);
  // eslint-disable-next-line
  let [genre, setGenre] = React.useState("");
  // eslint-disable-next-line
  let [movieName, setMovieName] = React.useState("");
  // eslint-disable-next-line
  let [drinkRecipe, setDrinkRecipe] = React.useState("");

  let [drink, setDrink] = React.useState(false);

  let showRelated = () => {
    setClick(!clicked);
    setGenre((genre = props.genre));
    setMovieName(props.name);
  };
  let showDrink = () => {
    setDrink(!drink);
    setDrinkRecipe(props.drinkRecipe);
  };

  let showRelatedMovies = clicked ? (
    <GetRelatedMovies genre={props.genre} name={props.name} />
  ) : null;

  let showDrinkRecipe = drink ? (
    <RecipeCard drinkRecipe={props.drinkRecipe} />
  ) : null;

  let body = (
    <div className="modal">
      <p className="modalCloseX" onClick={handleClose}>
        x
      </p>
      <div className="modal-content">
        <h2 className="tagline">"{props.tagline}"</h2>
        <div className="tagline-container">
          <span className="tagline-writer">- {props.taglineWriter}</span>
        </div>
        <div className="topPicsContainer">
          {showRelatedMovies}
          <img src={props.picture1} alt="movie poster" className="picture1" />
          <img src={props.picture2} alt="movie poster" className="picture2" />
        </div>
        <p className="modal-synopsis">{props.synopsis}</p>
        <div className="bonusFeatures">
          <div className="img-reviewed">
            <img
              className="img-img-reviewed"
              src={props.picture3}
              alt="movie cover"
            ></img>
            <a target="_blank" rel="noopener noreferrer" href={movieTitle}>
              <div className="img-overlay-reviewed">
                <div className="img-title-reviewed">Watch Me!</div>
                <p className="img-description-reviewed">Click to find where</p>
              </div>
            </a>
          </div>

          <div className="letsGo">
            <div className="bonusFeatureBulletContainer">SPECIAL FEATURES</div>
            <div className="bonusFeatureBullets">
              <ul>
                <li>{props.bulletOne}</li>
                <li className="bottomBullet">{props.bulletTwo}</li>
                <li className="seeSimilar" onClick={showRelated}>
                  {props.bulletThree} See related movies.
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="cast">
          <p className="castText">
            {props.cast} <div className="drinkRecipe">{showDrinkRecipe}</div>
          </p>
        </div>
        <div className="bottomContainerContainer">
          <div className="bottomContainer">
            <div className="pairsWith">
              <div className="iconContainer">
                <i
                  className="fas fa-cocktail fa-3x"
                  style={{ color: "#c5c1c1" }}
                ></i>
              </div>
              <div className="movieNameDrinks">
                <div className="movieNameDrinksText">{props.name}</div>

                <div className="pairsWithTextDiv">
                  Pairs well with{" "}
                  <span className="recommendedDrink" onClick={showDrink}>
                    {props.pairs}
                  </span>
                  <span className="noPairs">{props.noPairs}</span>
                </div>
              </div>
              <div className="iconContainer2">
                <i
                  className="fas fa-cocktail fa-3x"
                  style={{ color: "#c5c1c1" }}
                ></i>
              </div>
            </div>
          </div>
        </div>

        <div className="modal-filmInfo">
          <div className="modal-mpaa-rating">{props.rated}</div>
          <div className="modal-runtime">
            <i className="far fa-clock"></i>
            {props.runtime}
          </div>
          <div className="modal-year">{props.year}</div>
          <div className="modal-rating">
            <span role="img" aria-label="beer">
              🍺
            </span>
            {props.rating}
            <span className="outOf">/6</span>
            <span style={{ display: "none" }}>
              {props.genre}
              {props.subgenre}
            </span>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div>
      <img
        onClick={handleOpen}
        src={props.poster}
        alt="Movie Poster"
        className="moviePosters"
      />

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{
          backgroundColor: "rgba(0, 0, 0, 0.55)",
          overscrollBehavior: "none",
        }}
      >
        {body}
      </Modal>
    </div>
  );
}
