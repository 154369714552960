import React from "react";
import Footer from "./Footer";

function EmailSubscribe() {
  return (
    <div className="movieBackground">
      <div className="email-overlay">
        <div className="form-container">
          <h1 className="email-header">Join our email list</h1>
          <p className="email-text">
            We'll provide updates on our latest reviews and articles. We promise
            it won't get annoying.
          </p>
          <form
            name="email-subscribe"
            action="/EmailSubscribe"
            method="POST"
            id="email-form"
          >
            <input
              type="hidden"
              name="form-name"
              value="email-subscribe"
            ></input>
            <input required type="text" name="name" placeholder="Name"></input>
            <input required type="text" name="name" placeholder="Email"></input>
            <button className="randomButton" type="submit">
              Subscribe
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default EmailSubscribe;
