import React from "react";
import Fade from "react-reveal/Fade";
import Dolph from "../src/homepage-pictures/dolph.jpeg";
import Testimonial from "../src/Testimonial";
import { Link } from "react-router-dom";
import FSLogoNew from "./pictures/FSLogo/slobs-new.png";
import Parallax from "./Parallax";

function Home() {
  return (
    <div className="videoBackground">
      <Parallax />
      <section className="newSpace">
        <Fade bottom>
          <p>
            A <span className="newSpaceSpan">new space</span> for{" "}
            <span style={{ textDecoration: "underline" }}>
              <Link to="/ratings">movie reviews </Link>
            </span>
          </p>
        </Fade>
        <Fade bottom>
          <p className="newSpaceText">
            This site is for people who watch movies for pure
            <span className="green"> entertainment</span>
            <span className="green"> </span> and
            <span className="green"> enjoyment</span>. The world is stressful
            enough - let's forget about being overly critical and just relax
            with a good flick and a drink.
          </p>

          <p className="newSpaceText">
            If you're a film snob who sniffs at everything except
            black-and-white (f)arthouse films, you've come to the wrong place.
            If you're a <span class="green">FilmSlob</span> who loves
            <span className="green"> comedy, action,</span> and anything{" "}
            <span className="green">enjoyable</span>, you're home.
          </p>
        </Fade>
      </section>

      <section className="reviewsReimagined">
        <Fade bottom>
          <p className="reviewsReimaginedHeader">
            Ratings <span className="newSpaceSpan">Reimagined</span>
          </p>
          <p className="reviewsReimaginedText">
            We don't care about fancy French words or artsy symbolism. We
            specialize in reviews based on one simple question:&nbsp;
            <span class="green">was the movie entertaining? </span>
          </p>
          <p className="reviewsReimaginedText">
            We use a six-pack rating system where 1 beer is the worst (🍺
            <strong>1/</strong>
            <span className="outOfLarge">6</span>) and 6 is the best (🍺
            <strong>6/</strong>
            <span className="outOfLarge">6</span>)
          </p>
        </Fade>
      </section>

      <section className="celebTestimonials">
        <Fade bottom>
          <div
            class="buzzing"
            style={{
              fontSize: "50px",
              marginTop: "100px",
              fontFamily: "basic-sans,sans-serif",
              fontWeight: "900",
              textAlign: "center",
              fontSize: "60px",
            }}
          >
            The film industry is buzzing about{" "}
            <span class="green">FilmSlobs</span>
          </div>
          <Testimonial />
        </Fade>
      </section>

      <section className="reasonContainer">
        <Fade bottom>
          <section className="missingReason">
            <span className="newSpaceSpan">FilmSlobs</span> is the space that
            movie reviews have been missing because ...{" "}
          </section>
        </Fade>
        <Fade bottom>
          <div className="numberContainer">
            <div className="reasonNumber">1</div>
            <div className="reasonText-1">
              Our reviews come from regular people who want a few hours of
              entertainment; not pretentious snobs
            </div>
          </div>
        </Fade>
        <Fade bottom>
          <div className="numberContainer">
            <div className="reasonNumber">2</div>
            <div className="reasonText">
              You experience a hit of old school video store nostalgia while
              browsing in a modern way{" "}
            </div>
          </div>
        </Fade>
        <Fade bottom>
          <div className="numberContainer">
            <div className="reasonNumber">3</div>
            <div className="reasonText">
              All reviews come with unique drink pairings to elevate your
              viewing pleasure
            </div>
          </div>
        </Fade>
      </section>
    </div>
  );
}

export default Home;
