import React from "react";
import { Link } from "react-router-dom";
import ShareCircle from "../ShareCircle.js";
import ShareSquare from "../ShareSquare";
import Marble from "../coldtake-pictures/marble.jpeg";
import Croc from "../coldtake-pictures/croc.jpeg";
import Sunny from "../coldtake-pictures/nightman.jpeg";
import Happysad from "../coldtake-pictures/happy-sad-1.jpg";
import Butcher from "../coldtake-pictures/daylewis.jpeg";
import Somber from "../coldtake-pictures/somber.jpeg";
import Chan from "../coldtake-pictures/chan.png";
import Fraser from "../coldtake-pictures/fraser.jpeg";
import odevideo from "../grabbag-pictures/odethumbnail-black.jpeg";
import biggestguns from "../pictures/topfive/taglines.jpeg";
import RareSequel from "../pictures/topfive/sequelwins.jpeg";
import NotificationBell from "../NotificationBell";

function DramaIsCheap() {
  return (
    <div>
      <div className="currentRoute">
        <Link to="/articles">
          <span className="underline">Articles</span>
        </Link>{" "}
        &gt;{" "}
        <Link to="writingGrabbag">
          <span className="underline">Grab Bag</span>
        </Link>{" "}
        &gt; Drama is Cheap
      </div>
      <div className="articleContentContainer">
        <div class="article-container">
          <div class="article-container-header">
            <p>Drama is Cheap</p>
          </div>
          <div className="published">PUBLISHED Jul 17, 2023</div>
          <div className="authorName">
            BY{" "}
            <p>
              <Link to="/dean">
                <span className="green" id="author-name">
                  Dean
                </span>
              </Link>
            </p>
            <ShareCircle
              description={
                "Check out this article from FilmSlobs! Drama is Cheap"
              }
            />
          </div>
          <div class="article-container-body">
            <div className="image-and-caption">
              <img
                src={Sunny}
                className="article-image"
                alt={"Player Avatar"}
                style={{ objectFit: "fill" }}
              />
              <p id="image-caption">The opinion of every critic ever</p>
            </div>
            <p>
              We're sick and tired of drama scooping up all the awards and
              acclaim while comedy and action are treated like turds in a punch
              bowl. To be clear, we don't hate drama - drama is fine - but to
              act like it's the highest form of art is dead wrong. In this
              article we break down why{" "}
              <span className="green">
                comedy and action are better than drama and deserve more
                respect.
              </span>
            </p>

            <h2>Why Drama is Cheap</h2>

            <p>
              Drama is cheap because it deals with objective material without
              nuance or thought. Heartbreak and tragedy are{" "}
              <span className="green">universally depressing</span>. If you tell
              someone "I just lost my grandfather", you probably won't finish
              the sentence before they say "I'm so sorry!". It's essentially by
              rote. Drama is the block of marble before any sculpting happens.
            </p>
            <div className="image-and-caption">
              <img
                src={Marble}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">
                Wow, you managed to make prenatal heroin addiction sad? You're a
                genius!
              </p>
            </div>

            <h2>Why Comedy is Better</h2>
            <p>
              Comedy takes that raw slab and actually does something with it.
              Unlike drama, which rests on its laurels and lets you wallow in
              some teary-eyed snot-fest, comedy transforms that very same
              melancholy material into something amusing - which is infinitely
              more difficult to do.
            </p>
            <div className="image-and-caption">
              <div className="article-image-vertical-div">
                <img
                  src={Croc}
                  className="article-image-vertical"
                  alt={"Player Avatar"}
                />
              </div>
              <p id="image-caption">
                Nearly as difficult as fucking a crocodile
              </p>
            </div>
            <p>
              <span className="green">Comedy's superiority</span> lies in its
              subjectivity. What tickles one person's funny bone might leave
              another stone-faced. It's a high wire act that{" "}
              <span className="green">
                requires skill, timing, and delivery
              </span>{" "}
              to balance on the razor-thin line between hilarity and awkward
              silence.
            </p>
            <p>
              Skill, timing, and delivery don't even factor into drama. Try as
              you might, there's never a good moment to tell someone their dog
              got obliterated by a delivery truck.
            </p>

            <div className="image-and-caption">
              <div className="article-image-vertical-div">
                <img src={Happysad} alt={"Player Avatar"} />
              </div>
              <p id="image-caption">
                The impact knocked his <em>canines</em> loose!
              </p>
            </div>
            <p>
              Comedy is to drama as chess is to checkers. There's a vast
              difference in complexity and depth, yet comedy still gets no
              respect - just ask Rodney Dangerfield. If you need proof that
              comedy is harder, look at dramatic actors who've tried their hand
              in comedy. They're about as funny and relatable as a rectal polyp.
            </p>
            <p>
              The greatest actor ever, Daniel Day Lewis, has been artist, an oil
              prospector, a gang leader, a native American, and even Abraham
              Lincoln - but he's <em>never</em> been funny.
            </p>
            <div className="image-and-caption">
              <img
                src={Butcher}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">
                Butchered him. He'll receive the Bill for that Cutting.
              </p>
            </div>
            <p>
              Watching dramatic actors dabble in comedy is like watching a cat
              try to swim - it's unexpected, awkward, and you can't help but be
              mesmerized by how shitty it is.{" "}
              <span className="green">
                Comedic actors doing drama, on the other hand, are always
                fantastic
              </span>
              . Robin Williams, Jim Carrey, Bryan Cranston, Bill Murray, Bob
              Odenkirk, Jonah Hill, Steve Carell, Will Ferrell. The list goes on
              and on. They can walk into drama and murder it, but the reverse is
              categorically untrue.
            </p>
            <p>
              Putting aside all technical reasons for why comedy is better than
              drama (it's more difficult to write, it takes more skill to pull
              off, etc), <span className="green">comedy is just plain fun</span>
              , whereas drama is usually a drag. Next up, we have the action
              genre, flexing its muscles and outshining drama with every
              heart-pounding scene.{" "}
            </p>

            <h2>Why Action is Better</h2>
            <p>
              Action movies bring a whole new level of excitement thanks to the
              meticulous coordination of stunts, jaw-dropping choreography, and
              the sheer athleticism displayed by the actors. Every sequence is a
              symphony of high-flying kicks and gravity-defying feats - whereas
              dramatic scenes often require nothing more than a chair to sit in
              and a somber expression. How impressive.
            </p>
            <div className="image-and-caption">
              <img
                src={Somber}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">Holy shit. That sniffle was flawless.</p>
            </div>
            <p>
              Seriously, whats more difficult - shooting some weepy breakup
              scene in a diner in Kansas, or dangling from a helicopter ladder
              through the streets of Kuala Lumpur?
            </p>
            <div className="image-and-caption">
              <img src={Chan} className="article-image" alt={"Player Avatar"} />

              <p id="image-caption">Diner scene, obviously</p>
            </div>
            <p>
              Action movie stars truly take risks in their craft. They go
              through grueling physical training, push their bodies to the
              limit, and even endure bone-crushing injuries. Meanwhile the
              average dramatic actor's preparation involves devouring ice cream
              and shedding crocodile tears - and they're probably still divas
              about it.
            </p>
            <div className="image-and-caption">
              <img
                src={Fraser}
                className="article-image"
                alt={"Player Avatar"}
              />

              <p id="image-caption">"Ugh, my tear ducts are positively RAW"</p>
            </div>
            <h2>Conclusion</h2>
            <p>
              Action and comedy, as the dynamic duo of thrill and skill, are
              vastly superior to drama. Drama is like the snooze button of
              entertainment, while comedy and action bring the thunder and keep
              us on the edge of our seats.
            </p>
          </div>{" "}
          <Link to="/community">
            <div className="readMoreContainer">
              <p className="readMoreHeader">Read next:</p>
              <p className="readMoreContent">Lamest Community Episode</p>
              <p className="underscore"></p>
            </div>
          </Link>
          <ShareSquare
            description={
              "Check out this article from FilmSlobs! Drama is Cheap"
            }
          />
          <div>
            <Link to="/email">
              <NotificationBell />
            </Link>
          </div>
        </div>
        <div className="relatedSidebar">
          <p className="seeAlso">You may also like ...</p>
          <Link to="/biggestguns">
            <div className="image-and-caption">
              <img
                src={biggestguns}
                className="article-image-sidebar"
                alt={"Player Avatar"}
              />
              <p id="sidebar-image-caption">Biggest Guns on Movie Posters</p>
            </div>
          </Link>
          <Link to="/odevideostore">
            <div className="image-and-caption">
              <img
                src={odevideo}
                className="article-image-sidebar"
                alt={"Player Avatar"}
              />
              <p id="sidebar-image-caption">Ode to the video store</p>
            </div>
          </Link>
          <Link to="/raresequelwins">
            <div className="image-and-caption">
              <img
                src={RareSequel}
                className="article-image-sidebar"
                alt={"Player Avatar"}
              />
              <p id="sidebar-image-caption">Rare Sequel Wins</p>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default DramaIsCheap;
